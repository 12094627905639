import { useState, useRef, useEffect } from "react";
// Custom Hooks
import useSofia from '../../hooks/useSofia'  // Ajusta la ruta según la estructura del proyecto
// Reemplazar auth0 con manejo de cookies
import Cookies from "js-cookie"; // Asegúrate de instalar js-cookie: npm install js-cookie
// Chabot Bubbles
import {
  BotBubble,
  BotLoadingBubble,
  UserBubble,
  ErrorBubble,
} from "./TextBubbles";
// Chatbot Wrappers & Components
import {
  ChatHeader,
  ChatContainer,
  ChatContent,
  ChatTextbox,
} from "./ChatBot";
import { ToggleButton } from "./ToggleButton";
// Custom Markdown Elements
import {
  TextElement,
  ErrorTextElement,
  MarkdownComponent,
  CodeSnippet,
} from "./Functions/Markdown/MarkdownElements";
// sofIA functions
import { containsCode, containsTable } from "./Functions/validate"; // Ajusta la ruta según tu proyecto
import {
  setUserId,
  createUserInteraction,
  deleteErrorMessages,
  retrySendMessage,
} from "./Functions/functions";

export default function PiBot() {
  // * Custom hooks
  const { botIsWriting, getBotResponse } = useSofia();

  // * States
  const [chatIsOpen, setChatIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [chatMessages, setChatMessages] = useState([]);
  const [deletingMessages, setDeletingMessages] = useState(false);
  const [isMaximized, setIsMaximized] = useState(false); // Chatbot maximized

  // * Refs
  const chatContentRef = useRef(null);

  // * Helper functions
  // Toggle chatbot open state
  const toggleChat = () => {
    setChatIsOpen(!chatIsOpen);
  };

  // Toggle maximize state
  const toggleMaximize = () => {
    setIsMaximized(!isMaximized);
  };

  // Scroll to the bottom of the chat content
  const scrollToBottom = () => {
    chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
  };

  // Handle user message submission
  const handleMessage = async (e) => {
    e.preventDefault();
    if (message.trim() === "") return; // Prevent empty messages

    await createUserInteraction(message, chatMessages, {
      resetMessage: setMessage,
      updateMessages: setChatMessages,
      getBotResponse: getBotResponse,
    });
  };

  // * Effects
  // Initialize chat messages from localStorage
  useEffect(() => {
    const storedMessages = localStorage.getItem("storedChatMessages");
    if (storedMessages) {
      setChatMessages(JSON.parse(storedMessages));
    }

    // Set user ID using cookies
    const user = Cookies.get("user"); // Assumes user data is stored in cookies
    if (user) {
      setUserId(JSON.parse(user));
    }
  }, []);

  // Scroll to the bottom after messages are updated
  useEffect(() => {
    if (chatMessages.length === 0) return;

    if (chatIsOpen) {
      scrollToBottom();
    }

    localStorage.setItem("storedChatMessages", JSON.stringify(chatMessages));
  }, [chatMessages]);

  // Scroll to the bottom whenever the chat is opened
  useEffect(() => {
    if (chatIsOpen) {
      scrollToBottom();
    }
  }, [chatIsOpen]);

  return (
    <>
      {/* Bot Toggle */}
      <ToggleButton toggleFn={toggleChat} />
      {/* Chatbot */}
      {chatIsOpen && (
        <ChatContainer isMaximized={isMaximized}>
          {/* ChatHeader */}
          <ChatHeader
            isMaximized={isMaximized}
            controls={{
              size: toggleMaximize,
              close: toggleChat,
            }}
          />
          {/* ChatContent */}
          <ChatContent chatRef={chatContentRef} isMaximized={isMaximized}>
            {/* sofIA Intro Message */}
            <BotBubble>
              <TextElement>
                ¡Hola! Soy PiBot, si tienes alguna duda o pregunta sobre alguno
                de los contenidos del programa, estoy aquí para escucharte,
                guiarte y apoyarte en lo que necesites.
              </TextElement>
            </BotBubble>
            <BotBubble>
              <TextElement>¿En qué puedo ayudarte hoy? 😊</TextElement>
            </BotBubble>
            {/* Dynamic messages */}
            {chatMessages.map((chatMessage) => {
              if (chatMessage.author === "user") {
                return (
                  <UserBubble key={chatMessage.id}>
                    {chatMessage.content}
                  </UserBubble>
                );
              } else if (!chatMessage.error) {
                return (
                  <BotBubble key={chatMessage.id}>
                    {containsTable(chatMessage.content) ? (
                      <MarkdownComponent content={chatMessage.content} />
                    ) : containsCode(chatMessage.content) ? (
                      <CodeSnippet
                        code={chatMessage.content}
                        customStyles={{
                          width: "fit-content",
                          maxWidth: "70%",
                          borderRadius: "0.5rem",
                          fontSize: "12px",
                          margin: 0,
                        }}
                      />
                    ) : (
                      <MarkdownComponent content={chatMessage.content} />
                    )}
                  </BotBubble>
                );
              } else {
                return (
                  <ErrorBubble
                    key={chatMessage.id}
                    messageId={chatMessage.id}
                    messages={chatMessages}
                    actions={{
                      retry: retrySendMessage,
                      delete: {
                        fn: deleteErrorMessages,
                        callbacks: {
                          deletingMessages: setDeletingMessages,
                          updateMessages: setChatMessages,
                        },
                      },
                    }}
                  >
                    <ErrorTextElement>{chatMessage.content}</ErrorTextElement>
                  </ErrorBubble>
                );
              }
            })}
            {/* Conditional Loading */}
            {botIsWriting && (
              <BotLoadingBubble />
            )}
          </ChatContent>
          {/* Chatbox input */}
          <ChatTextbox
            submitFn={handleMessage}
            message={{
              value: message,
              update: setMessage,
            }}
            disabled={{
              input: botIsWriting || deletingMessages,
              button: message === "" || deletingMessages,
            }}
          />
        </ChatContainer>
      )}
    </>
  );
}
