import React, { useEffect } from "react";
import styles from "./Styles/TextBubbles.module.css";
import PibotImages from "../../assets/pibot/ExportPibotImages";

export function BotLoadingBubble() {
    return (
        <div className={styles.botContainer}>
            {/* Imagen del bot */}
            <img
                className={styles.imgBot}
                src={PibotImages.PiBotBlue} // Ruta válida de la imagen
                alt="bot-profile"
            />
            {/* Burbuja de carga */}
            <div className={styles.loadingBubble}>
                <div className={styles.dot}></div> {/* Primer punto */}
                <div className={styles.dot}></div> {/* Segundo punto */}
                <div className={styles.dot}></div> {/* Tercer punto */}
            </div>
        </div>
    );
}

// Bot reply bubble
export function BotBubble({ children }) {
    return (
        <div className={styles.containerBubble}>
            <img
                className={styles.img}
                src={PibotImages.PiBotBlue}
                alt="bot-profile"
            />
            {children}
        </div>
    );
}

// Bot error reply bubble
export function ErrorBubble({ children, actions, messageId, messages }) {
    return (
        <div className={styles.container}>
            <img
                className={styles.img}
                src={PibotImages.PiBotBlue}
                alt="bot-profile"
            />
            <div className={styles.bubbleContainer}>
                {children}
                <div className={styles.actions}>
                    <button
                        className={`${styles.actionButton} ${styles.retry}`}
                        onClick={(e) => actions.retry(e, messageId, messages)}
                    >
                        Reintentar
                    </button>
                    <button
                        className={`${styles.actionButton} ${styles.delete}`}
                        onClick={(e) =>
                            actions.delete.fn(e, messageId, messages, actions.delete.callbacks)
                        }
                    >
                        Eliminar
                    </button>
                </div>
            </div>
        </div>
    );
}

export function UserBubble({ children }) {
    return <div className={styles.bubble}>{children}</div>;
}

