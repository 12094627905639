import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/prism";
import css from "./Markdown.module.css";

// Para tablas
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const DEFAULT_CUSTOM_STYLES = {
  width: "fit-content",
  borderRadius: "0.5rem",
  margin: 0,
};

// <a/> Element
export function LinkElement({ href, children }) {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "#007bff", textDecoration: "underline" }} // Reemplazo de clases
    >
      {children}
    </a>
  );
}

// <p/> Element
export function TextElement({ children }) {
  return (
    <p className={css.markdownContainer}>
      {children}
    </p>
  );
}

// <p/> Element with error
export function ErrorTextElement({ children }) {
  return (
    <p
      style={{
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        padding: "8px",
        borderRadius: "8px",
        fontSize: "0.875rem",
        backgroundColor: "#ffe4e6", // bg-accent-hot-stuff-500/5
        color: "#dc2626", // text-status-danger-600
        margin: "0",
      }}
    >
      {children}
    </p>
  );
}

// Custom code snippet with syntax highlight
export function CodeSnippet({ code, customStyles = DEFAULT_CUSTOM_STYLES }) {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px", width: "100%" }}>
      <ReactMarkdown
        components={{
          code({ node, inline, className, children, ...props }) {
            if (inline) {
              return (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            }
            const match = /language-(\w+)/.exec(className || "");
            if (match) {
              const formattedChildren = String(children).replace(/\n$/, "");
              return (
                <SyntaxHighlighter
                  customStyle={customStyles}
                  wrapLines={true}
                  wrapLongLines={true}
                  style={darcula}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {formattedChildren}
                </SyntaxHighlighter>
              );
            } else {
              return (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            }
          },
          p: TextElement,
        }}
      >
        {code}
      </ReactMarkdown>
    </div>
  );
}

// Componente Markdown
export function MarkdownComponent({ content }) {
  if (content.includes("```")) {
    content = content.replace(/```/g, "");
  }

  return (
    <div className={css.markdownContainer}>
      <ReactMarkdown
        components={{ a: LinkElement }}
        rehypePlugins={[rehypeRaw]}
        remarkPlugins={[remarkGfm]}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}
