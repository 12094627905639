import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import { getModulesProgress } from '../../models/modules';
import Cookies from 'js-cookie'

import css from './ProgressPage.module.css';

const ProgressPage = () => {
    const { setAuthUser, isLoggedIn, setIsLoggedIn, authUser } = useAuth()
    const navigate = useNavigate();

    const [progressLoader, setProgressLoader] = useState(false);
    const [modules, setModules] = useState([]);
    const [showCompleted, setShowCompleted] = useState(true);

    useEffect(() => {
        if (!isLoggedIn) {
            const authCookie = Cookies.get('authUser')
            if (authCookie) {
                console.log('Login found in cookies')
                setIsLoggedIn(true)
            } else {
                console.log('No auth cookie found, redirecting to login')
                navigate('/login')
            }
        }
    }, [isLoggedIn, setAuthUser, setIsLoggedIn, navigate])

    useEffect(() => {
        const fetchModules = async () => {
            const response = await getModulesProgress(authUser.id);
            setModules(response);
            setProgressLoader(true);
        };

        if (isLoggedIn && authUser) {
            fetchModules();
        }
    }, [authUser, isLoggedIn]);

    const filteredModules = showCompleted
        ? modules
        : modules.filter(module => module.progress < 100);

    return (
        <div className={css.container}>
            {!progressLoader ? (
                <div className={css.spinnerContainer}>
                    <div className={css.spinner}></div>
                    <p>Cargando módulos...</p>
                </div>
            ) : (
                <>
                    <div className={css.titlesection}>
                        <div className={css.titles}>
                            <h2>Te damos la bienvenida</h2>
                            <h1>{authUser && authUser.name}</h1>

                        </div>

                        <div className={css.filter}>
                            <label className={css.checkboxLabel}>
                                <input
                                    type="checkbox"
                                    checked={showCompleted}
                                    onChange={() => setShowCompleted(prev => !prev)}
                                    className={css.checkbox}
                                />
                                Mostrar módulos completados
                            </label>
                        </div>

                    </div>
                    <div className={css.modules}>
                        {filteredModules.length > 0 ? (
                            filteredModules.map((module, index) => {
                                const isDisabled =
                                    index > 0 && filteredModules[index - 1].progress < 100; // Bloquear acceso solo si no es el primer módulo y el anterior no está completo.

                                return (
                                    <div
                                        key={index}
                                        className={`${css.moduleCard} ${isDisabled ? css.disabled : ''}`}
                                        onClick={() => {
                                            if (!isDisabled) {
                                                navigate("/vocacionamiento?module=" + module.id_module);
                                            }
                                        }}
                                    >
                                        <img
                                            src={module.module_image || "/banner.png"}
                                            alt={module.title}
                                            className={css.moduleImage}
                                        />
                                        <div className={css.moduleInfo}>
                                            <h3>{module.module_title}</h3>
                                            {isDisabled ? (
                                                <p className={css.lockedMessage}>
                                                    Completa el módulo anterior para desbloquear este.
                                                </p>
                                            ) : (
                                                <>
                                                    <p>
                                                        Progreso: {parseInt(module.progress)}%
                                                    </p>
                                                    <div className={css.progressBar}>
                                                        <div
                                                            className={css.progress}
                                                            style={{ width: `${module.progress}%` }}
                                                        ></div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <p>No hay módulos disponibles para mostrar.</p>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default ProgressPage;
