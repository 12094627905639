import Chevron from "./chevron.png";
import ChevronsRight from "./Chevrons_right.png";
import PiBotBlue from "./PiBotBlue.png";
import SofiaImg from "./sofia-img.png";
import Tecmi from "./tecmi.png";
import Trophy from "./trophy.jpg";

const PibotImages = {
  Chevron,
  ChevronsRight,
  PiBotBlue,
  SofiaImg,
  Tecmi,
  Trophy,
};

export default PibotImages;
