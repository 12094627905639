import header from "../../assets/arrow-header.png";
import footer from "../../assets/arrow-footer.png";
import down from "../../assets/arrow-down.png";
import right from "../../assets/arrow-right.png";
import ProfilesPics from "./ProfilesPics/ProfilesPic";
import "./Tendencias.css"
import { useEffect, useRef, useState } from "react";
import debounce from "lodash.debounce";


function Tendencias({ tendenciasData }) {
  const levels = [
    "Pre-Entry",
    "Entry",
    "Proficient",
    "Manager",
    "Líder",
    "Chief",
  ];

  let level = 1
  levels.map((level, index) => {
    console.log(level, index)
    if (level === tendenciasData.optionYears) {
      level = index + 1
    }
  })

  const containerRef = useRef(null);
  const [isCompact, setIsCompact] = useState(false);

  const ProfilesTexts = {
    AUTONOMO: {
      lifestyle:
        "Generalmente dedicados a actividades de auto-empleo o autonomía dentro de las empresas, tienen un largo historial de auto-aprendizaje, en donde no necesitan guías ni maestros para aprender nuevas habilidades.",
      personality:
        "Práctica y acertada, tienen la capacidad de identificar oportunidades y los conocimientos que necesitan desarrollar, y de aprenderlos sin necesitar ayuda externa.",
      personalChallenges: [
        "Mantenerse motivado y enfocado en el desarrollo continuo de habilidades, incluso cuando no hay una estructura externa de apoyo.",
        "Gestionar el tiempo de manera eficiente para equilibrar el aprendizaje de nuevas habilidades con otras responsabilidades personales y profesionales.",
        "Asegurarse de mantener un equilibrio saludable entre la búsqueda de nuevas oportunidades y el disfrute de la vida personal.",
      ],
      professionalChallenges: [
        "Evitar la complacencia y la autosatisfacción al identificar y aprovechar oportunidades, manteniendo una mentalidad de crecimiento constante.",
        "Demostrar el valor de las habilidades aprendidas de forma autodidacta en un entorno profesional, especialmente si no se cuenta con certificaciones o credenciales formales.",
        "Superar posibles obstáculos o resistencia en el lugar de trabajo al implementar nuevas ideas y conocimientos adquiridos de manera independiente.",
      ],
    },
    FANTASIOSO: {
      lifestyle: "Enfrascada en sus actividades de día a día, es una persona que no tiene tiempo para dedicar a actividades adicionales; pero ha imaginando escenarios en los cuales podría alterar su situación de vida, que no tiene intenciones inmediatas de concretar.",
      personality:
        "Tiene una imaginación poderosa para pensar en potencial, pero no se caracteriza por tangibilizar planes adicionales, y puede quedarse enfrascado en las posibilidades.",
      personalChallenges: [
        "Concretar objetivos: A pesar de su imaginación poderosa, puede tener dificultades para convertir ideas en planes tangibles y alcanzables. Necesita definir objetivos claros y pasos concretos para lograrlos.",
        "Gestión del tiempo: Quedarse enfrascado en las posibilidades puede llevar a falta de enfoque y procrastinación. Debe aprender a gestionar su tiempo eficazmente para avanzar en sus proyectos.",
        "Autoconfianza: Podría dudar de su capacidad para concretar proyectos. Trabajar en su autoconfianza y creer en su capacidad será crucial.",
      ],
      professionalChallenges: [
        "Ideas a proyectos: Convertir ideas en proyectos laborales es un desafío. Debe comunicar sus visiones de forma persuasiva.",
        "Trabajo en equipo: Dificultades en la colaboración pueden surgir. Aprender a trabajar en equipo y comprometerse será clave.",
        "Enfoque en resultados: Mantenerse enfocado en resultados medibles es importante. Establecer y seguir objetivos claros es esencial.",
      ],
    },
    GENERALISTA: {
      lifestyle: "Personas que sienten fascinación por la diversidad de estímulos disponibles para ellos en diferentes áreas de interés y están convencidos de que hay que explorar el mundo para obtener el placer del aprendizaje.",
      personality:
        "Variedad de intereses, mente abierta y capacidad de adaptación a diferentes situaciones y contextos.",
      personalChallenges: [
        "Gestión del tiempo: Equilibrar responsabilidades laborales, académicas y personales con el desarrollo de habilidades es desafiante.",
        "Motivación y disciplina: Mantener la motivación y disciplina para dedicar tiempo al desarrollo de habilidades adicionales puede ser difícil.",
        "Priorización de objetivos: Identificar y priorizar qué habilidades desarrollar y cómo integrarlas en la vida diaria requiere planificación y reflexión cuidadosas.",
      ],
      professionalChallenges: [
        "Integración de habilidades: Adaptar nuevas habilidades en la vida profesional actual puede necesitar tiempo y esfuerzo extra. Reconocimiento laboral: Hacer que las nuevas habilidades sean valoradas por empleadores o colegas puede ser un desafío.",
        "Aplicación de habilidades: Encontrar oportunidades para usar nuevas habilidades en el trabajo requiere creatividad y proactividad.",
      ],
    },
    PLANEADOR: {
      lifestyle: "Son personas que han dominado poco a poco sus líneas de ingreso y tienen una necesidad latente por crecer sus capacidades para mejorar o mantener su estilo de vida bajo un plan de vida muy estructurado. Tienen claridad sobre las habilidades requeridas para conseguir un futuro mejor y tienen un plan claro para adquirirlas y dar el salto.",
      personality:
        "Con capacidad de distinguir oportunidades para su crecimiento, es ordenada, práctica y con ganas de dar siempre un esfuerzo extra para poder llegar al lugar deseado.",
      personalChallenges: [
        "Ampliar su conocimiento y habilidades en áreas específicas relevantes para su carrera actual o aspiraciones profesionales futuras",
        "Perfeccionismo: Dado que tienden a ser precisos y acertados, podrían luchar con la tendencia al perfeccionismo, lo que puede ralentizar su progreso o hacerles sentir insatisfechos con sus logros.",
        "Establecer metas claras: A veces, pueden sentirse abrumados por la cantidad de oportunidades y conocimientos disponibles, por lo que podrían necesitar trabajar en establecer metas claras y priorizar qué habilidades desean desarrollar primero.",
      ],
      professionalChallenges: [
        "Avanzar en su carrera mediante la obtención de promociones o ascensos a roles de mayor responsabilidad y liderazgo",
        "A pesar de ser capaces de aprender por sí mismos, pueden enfrentar dificultades para mantener la motivación a largo plazo, especialmente si no reciben reconocimiento externo por sus logros.",
      ],
    },
  };


  const sampleData = {
    profile: tendenciasData.optionArquetipo || "UNKNOWN", ...ProfilesTexts[tendenciasData.optionArquetipo || "UNKNOWN"],
    avatar: ProfilesPics[tendenciasData.optionArquetipo] || "UNKNOWN",
    currentLevel: level,
    workStyles: [
      {
        title: "AFINIDAD CON EL AUTOEMPLEO",
        startLabel: "Empleado",
        endLabel: "Emprendedor",
        start: tendenciasData.optionEmprendedor.type === "Emprendedor" ? tendenciasData.optionEmprendedor.percentage : 100,
        end: tendenciasData.optionEmprendedor.type === "Empleado" ? tendenciasData.optionEmprendedor.percentage : 100,
      },
      {
        title: "AFINIDAD CON LA AUTONOMÍA LABORAL",
        startLabel: "Autonomía",
        endLabel: "Supervisión",
        start: tendenciasData.optionAutonomia.type === "Supervisión" ? tendenciasData.optionAutonomia.percentage : 100,
        end: tendenciasData.optionAutonomia.type === "Autonomía" ? tendenciasData.optionAutonomia.percentage : 50,
      },
      {
        title: "PRIORIDADES PARA ELEGIR UN TRABAJO",
        startLabel: "Conveniencia",
        endLabel: "Significativo",
        start: tendenciasData.optionPasionVsDinero.type === "Significativo" ? tendenciasData.optionPasionVsDinero.percentage : 100,
        end: tendenciasData.optionPasionVsDinero.type === "Conveniencia" ? tendenciasData.optionPasionVsDinero.percentage : 100,
      },
      {
        title: "PREFERENCIA DE ESTILO DE TRABAJO",
        startLabel: "Presencial",
        endLabel: "Remoto",
        start: tendenciasData.optionModalidad.type === "Remoto" ? tendenciasData.optionModalidad.percentage : 100,
        end: tendenciasData.optionModalidad.type === "Presencial" ? tendenciasData.optionModalidad.percentage : 100,
      },
    ],
  };


  const chart = [];

  levels.forEach((level, index) => {
    // Case 1: Current index is below user's level
    if (index + 1 < sampleData.currentLevel) {
      console.log("Current index is below user's level")
      chart.push(
        <div className="chart-below">
          <span>{level}</span>
          <div className="chart-index">
            <p>{index + 1}</p>
          </div>
          <div className={`chart-line line-${(index + 1)}`}></div>
          <div className="chart-dot"></div>
        </div>
      );
    }

    // Case 2: Current index is equal to user's level
    if (index + 1 === sampleData.currentLevel) {
      console.log("Current index is equal user's level")
      chart.push(
        <div className="chart-equal">
          <img className="current-arrow" src={down} alt="" />
          <span className="current-label">actual</span>
          <span>{level}</span>
          <div className="chart-index">
            <p>{index + 1}</p>
          </div>
          <div className={`chart-line line-${(index + 1)}`}></div>
          <div className="chart-dot"></div>
        </div>
      );
    }

    // Case 3: Current index is grater than user's level
    if (index + 1 > sampleData.currentLevel) {
      console.log("Current index is greater than user's level")
      chart.push(
        <div className="chart-above">
          <img className="above-arrow" src={right} alt="" />
          <span>{level}</span>
          <div className="chart-index">
            <p>{index + 1}</p>
          </div>
          <div className={`chart-line line-${(index + 1)}`}></div>
          <div className="chart-dot"></div>
        </div>
      );
    }
  });

  const styles = [];

  sampleData.workStyles.forEach((style) => {
    // Case 1: the start value of the spectrum is greater than the end value
    if (style.start > style.end) {
      styles.push(
        <div className="trend-item">
          <h4>{style.title}</h4>
          <div className="trend-data">
            <p className="high-label">{style.startLabel}</p>
            <div className="trend-bar">
              <div className="bar-wrapper">
                <div
                  className="bar-start"
                  style={{ width: `${style.start}%` }}
                ></div>
              </div>

              <div className="bar-wrapper">
                <div
                  className="bar-end"
                  style={{ width: `${style.end}%` }}
                ></div>
              </div>
            </div>
            <p className="low-label">{style.endLabel}</p>
          </div>
        </div>
      );
    }

    // Case 2: the start and end values of the spectrum are the same
    if (style.start === style.end) {
      styles.push(
        <div className="trend-item">
          <h4>{style.title}</h4>
          <div className="trend-data">
            <p className="high-label">{style.startLabel}</p>
            <div className="trend-bar">
              <div className="bar-wrapper">
                <div
                  className="bar-start"
                  style={{ width: `${style.start}%` }}
                ></div>
              </div>

              <div className="bar-wrapper">
                <div
                  className="bar-end"
                  style={{ width: `${style.end}%` }}
                ></div>
              </div>
            </div>
            <p className="high-label">{style.endLabel}</p>
          </div>
        </div>
      );
    }

    // Case 3: the start value of the spectrum is less than the end value
    if (style.start < style.end) {
      styles.push(
        <div className="trend-item">
          <h4>{style.title}</h4>
          <div className="trend-data">
            <p className="low-label">{style.startLabel}</p>
            <div className="trend-bar">
              <div className="bar-wrapper">
                <div
                  className="bar-start"
                  style={{ width: `${style.start}%` }}
                ></div>
              </div>

              <div className="bar-wrapper">
                <div
                  className="bar-end"
                  style={{ width: `${style.end}%` }}
                ></div>
              </div>
            </div>
            <p className="high-label">{style.endLabel}</p>
          </div>
        </div>
      );
    }
  });

  const personalChallenges = [];
  (sampleData.personalChallenges || []).forEach((challenge) => {
    personalChallenges.push(<li>{challenge}</li>);
  });

  const professionalChallenges = [];
  (sampleData.professionalChallenges || []).forEach((challenge) => {
    professionalChallenges.push(<li>{challenge}</li>);
  });

  return (
    <div
      ref={containerRef}
      className={`tendencias-layout ${isCompact ? "compact-mode" : ""}`}
    >
      <div className="tendencias-layout">
        <img className="tendencias-header" src={header} alt="" />
        <h2>Tendencias aspiraciones profesionales</h2>
        <div className="tendencias-columns">
          <div className="tendencias-profile">
            <img className="tendencias-avatar" src={sampleData.avatar} alt={sampleData.profile} />
            <h4>Tu perfil es</h4>
            <h3>{tendenciasData.optionArquetipo}</h3>
            <div className="tendencias-chart">{chart}</div>
          </div>
          <div className="tendencias-styles">
            <h3>Estilo de trabajo</h3>
            {styles}
          </div>
        </div>
        <div className="tendencias-info">
          <div className="tendencias-text">
            <h4>Estilo de vida</h4>
            <p>{sampleData.lifestyle}</p>
          </div>
          <div className="tendencias-text">
            <h4>Personalidad</h4>
            <p>{sampleData.personality}</p>
          </div>
          <div className="tendencias-text">
            <h4>Retos Personales</h4>
            <ol>{personalChallenges}</ol>
          </div>
          <div className="tendencias-text">
            <h4>Retos Profesionales</h4>
            <ol>{professionalChallenges}</ol>
          </div>
        </div>
        <img className="tendencias-footer" src={footer} alt="" />
      </div>
    </div>
  );
}

export default Tendencias;