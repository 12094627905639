import React, { useEffect, useState } from 'react'
import logo from '../../assets/pivotea-logo-green.png'
import banner from '../../assets/green-banner.png'
import lightning from '../../assets/lightning.png'
import styles from './ProyectoAprendizaje.module.css'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import Cookies from 'js-cookie'

function ProyectoAprendizaje({ aprendizajeData }) {
  const [name, setName] = useState('EEEE')
  const navigate = useNavigate()
  const { setAuthUser, isLoggedIn, setIsLoggedIn, authUser } = useAuth()

  useEffect(() => {
    if (!isLoggedIn) {
      const authCookie = Cookies.get('authUser')
      if (authCookie) {
        console.log('Login found in cookies')
        const user = JSON.parse(authCookie)
        setIsLoggedIn(true)
      } else {
        console.log('No auth cookie found, redirecting to login')
        navigate('/login')
      }
    }
    setName(authUser?.name)
  }, [isLoggedIn, setAuthUser, setIsLoggedIn, navigate])

  const strengths = []
  aprendizajeData.answersFortalezas.forEach((fortaleza) => {
    strengths.push(
      <>
        <li>{fortaleza.answer}</li>
        <span>{fortaleza.description}</span>
      </>
    )
  })

  const opportunities = []
  const opportunitiesTemp = []

  const splitStringAtHalf = (string) => {
    const middleIndex = Math.floor(string.length / 2)
    const spaceIndex = string.indexOf(' ', middleIndex)

    if (spaceIndex === -1) {
      // No hay espacio después de la mitad del string, no se puede dividir
      return [string]
    }

    const part1 = string.substring(0, spaceIndex)
    const part2 = string.substring(spaceIndex + 1)

    return [part1, part2]
  }
  for (let i = 0; i < aprendizajeData.answersOportunidades.length; i++) {
    const halfString = splitStringAtHalf(aprendizajeData.answersOportunidades[i + 1].answer)

    opportunitiesTemp.push({
      title: aprendizajeData.answersOportunidades[i].answer,
      statement: halfString[0],
      justification: halfString[1],
    })
    i++
  }
  
  opportunitiesTemp.forEach((opportunity) => {
    opportunities.push(
      <div className={styles.opportunity}>
        <div className={styles.oppHeader}>{opportunity.title}</div>
        <span>{opportunity.statement}</span>
        <p>{opportunity.justification}</p>
      </div>
    )
  })
  return (
    <div className={styles.proyectoLayout}>
      <div className={styles.proyectoHeader}>
        <img src={logo} className={styles.proyectoLogo} alt="Pivotea" />
        <p>Proyecto de aprendizaje</p>
      </div>
      <div className={styles.proyectoBanner}>
        <h3>{name}</h3>
        <p>{aprendizajeData.answersProposito[0].answer}</p>
        <img src={banner} alt="" />
      </div>
      <div className={styles.proyectoColumns}>
        <div className={styles.strengths}>
          <img src={lightning} alt="" />
          <h4>Fortalezas de carácter</h4>
          <ol>{strengths}</ol>
        </div>
        <div className={styles.opportunities}>
          <h4>
            Portafolio de vida:
            <br />
            Mis áreas de mejora
          </h4>
          {opportunities}
        </div>
      </div>
    </div>
  )
}

export default ProyectoAprendizaje
