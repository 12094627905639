import React from "react";
import styles from "./Styles/ChatBot.module.css";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import CloseIcon from "@mui/icons-material/Close";
import PibotImages from "../../assets/pibot/ExportPibotImages";


export function ChatContainer({ children, isMaximized }) {
    return (
        <div
            className={`${styles.chatContainer} ${isMaximized
                    ? styles.maximizedChatContainer
                    : styles.standardChatContainer
                }`}
        >
            {children}
        </div>
    );
}

// Chatbot content
export function ChatContent({ children, isMaximized, chatRef }) {
    return (
        <div
            ref={chatRef}
            className={`${styles.chatContent} ${isMaximized ? styles.maximizedChatContent : styles.standardChatContent
                }`}
        >
            {children}
        </div>
    );
}

// Chatbot header
export function ChatHeader({ controls, isMaximized }) {
    return (
        <div className={styles.header}>
            <h6 className={styles.title}>PiBot</h6>
            <div className={styles.controls}>
                <button className={styles.iconButton} onClick={controls.size}>
                    {isMaximized ? (
                        <CloseFullscreenIcon className={styles.icon} />
                    ) : (
                        <OpenInFullIcon className={styles.icon} />
                    )}
                </button>
                <button className={styles.iconButton} onClick={controls.close}>
                    <CloseIcon className={styles.icon} />
                </button>
            </div>
        </div>
    );
}

// Chatbot text box
export function ChatTextbox({ submitFn, message, disabled }) {
    return (
        <form onSubmit={submitFn} className={styles.textboxContainer}>
            <input
                className={styles.input}
                disabled={disabled.input}
                value={message.value}
                onChange={(e) => message.update(e.target.value)}
                type="text"
                name="message"
                placeholder="Escribe..."
            />
            <button
                disabled={disabled.button}
                className={styles.sendButton}
                type="submit"
            >
                <img
                    className={styles.chevron}
                    height="24"
                    width="24"
                    src={PibotImages.ChevronsRight}
                    alt="chevron"
                />
            </button>
        </form>
    );
}