import React, { useState, useEffect } from "react";
import "./FileUpload.css";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import axios from "axios";
import Toast from "../../components/Toast/Toast";

function FileUpload(props) {
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [fileState, setFileState] = useState(props.answer || null);

  // Manejar el cierre del snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  // Subir archivo
  const onChange = async (event) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", event.target.files[0]);

    const url = process.env.REACT_APP_API_URL || "http://localhost:3501";

    try {
      const response = await axios.post(`${url}/answers/upload`, formData);
      const ansObj = {
        block_id: props.userData.block,
        user_id: props.userData.id,
        answer: response.data.filename,
        question_type: "file_upload",
        id_question: props.userData.id_question,
        question: props.userData.question,
      };
      
      setFileState(response.data.filename);
      props.fileCallback(ansObj);
      setToastMessage("Archivo subido con éxito.");
    } catch (error) {
      console.error("Error al subir el archivo:", error);
      setToastMessage("Error al subir el archivo.");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    setFileState(props.answer);
  }, [props.answer]);

  // Borrar archivo
  const onDelete = async () => {
    const url = process.env.REACT_APP_API_URL || "http://localhost:3501";
    setLoading(true);
    
    try {
      await axios.post(`${url}/answers/delete-blob`, {
        blobName: props.answer, 
      });

      await axios.post(`${url}/answers/delete-answer`, {
        data: {
          answer: props.answer, 
        },
      });
      debugger

      // Resetea la respuesta asociada
      const emptyAnswer = {
        id_question: props.userData.id_question,
        answer: null, // Indica que no hay respuesta
      };

      setFileState(null);
      props.fileCallback(emptyAnswer); // Actualiza el estado de la respuesta
      setToastMessage("Archivo eliminado con éxito.");
    } catch (error) {
      console.error("Error al eliminar el archivo:", error);
      setToastMessage("Error al eliminar el archivo.");
    } finally {
      setLoading(false);
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      {fileState ? (
        <div className="uploaded-file-container">
          <a
            className="uploaded-file"
            target="_blank"
            rel="noopener noreferrer"
            href={`https://${process.env.REACT_APP_AZURE_BLOB_ACCOUNT_NAME}.blob.core.windows.net/answers/${props.answer}`}
          >
            <InsertDriveFileOutlinedIcon /> Ver archivo: {props.answer}
          </a>
          <button
            className="delete-button"
            disabled={loading}
            onClick={onDelete}
          >
            <DeleteOutlineIcon /> Eliminar
          </button>
        </div>
      ) : (
        <input
          disabled={loading}
          className="file-upload"
          type="file"
          onChange={onChange}
        ></input>
      )}
      <Toast
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={toastMessage}
      />
    </>
  );
}

export default FileUpload;
