const Profile = () => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.16634 4.99935C5.16634 3.43454 6.43487 2.16602 7.99967 2.16602C9.56448 2.16602 10.833 3.43454 10.833 4.99935C10.833 6.56416 9.56448 7.83268 7.99967 7.83268C6.43487 7.83268 5.16634 6.56416 5.16634 4.99935ZM7.99967 3.16602C6.98715 3.16602 6.16634 3.98683 6.16634 4.99935C6.16634 6.01187 6.98715 6.83268 7.99967 6.83268C9.0122 6.83268 9.83301 6.01187 9.83301 4.99935C9.83301 3.98683 9.0122 3.16602 7.99967 3.16602Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33301 9.83268C4.50458 9.83268 3.83301 10.5043 3.83301 11.3327V12.1249C3.83301 12.1369 3.84175 12.1472 3.85365 12.1492C6.59949 12.5975 9.39986 12.5975 12.1457 12.1492C12.1576 12.1472 12.1663 12.1369 12.1663 12.1249V11.3327C12.1663 10.5043 11.4948 9.83268 10.6663 9.83268H10.4391C10.4215 9.83268 10.4041 9.83546 10.3874 9.84091L9.81035 10.0293C8.6338 10.4135 7.36555 10.4135 6.189 10.0293L5.61199 9.84091C5.59528 9.83546 5.57782 9.83268 5.56025 9.83268H5.33301ZM2.83301 11.3327C2.83301 9.95197 3.9523 8.83268 5.33301 8.83268H5.56025C5.68325 8.83268 5.80547 8.85213 5.92239 8.89031L6.4994 9.07872C7.47426 9.39704 8.52509 9.39704 9.49995 9.07872L10.077 8.89031C10.1939 8.85213 10.3161 8.83268 10.4391 8.83268H10.6663C12.0471 8.83268 13.1663 9.95197 13.1663 11.3327V12.1249C13.1663 12.627 12.8024 13.0552 12.3068 13.1361C9.45428 13.6018 6.54507 13.6018 3.69252 13.1361C3.19693 13.0552 2.83301 12.627 2.83301 12.1249V11.3327Z"
        fill="black"
      />
    </svg>
  );
  
  export default Profile;
  