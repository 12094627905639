import PibotImages from "../../assets/pibot/ExportPibotImages";
import styles from "./Styles/ToggleButton.module.css";

export function ToggleButton({ toggleFn }) {
    return (
        <button
            onClick={toggleFn}
            className={`${styles.button} ${styles.small} sm:${styles.large}`}
        >
            <img
                className={styles.img}
                src={PibotImages.PiBotBlue}
                alt="sofia-profile"
            />
        </button>
    );
}