import React from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";


const StyledSnackbar = styled(Snackbar)({
    "& .MuiAlert-root": {
      backgroundColor: "#000000", // Fondo oscuro
      color: "#FFFFFF", // Texto blanco
    },
    "& .MuiAlert-icon": {
      color: "#2FD9C3", // Color del icono de éxito (verde)
    },
});

function Toast({ open, onClose, message }) {
    return (
      <StyledSnackbar
        open={open}
        autoHideDuration={6000}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          icon={<CheckCircleIcon fontSize="inherit"/>}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={onClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
          severity="success"
          sx={{ width: "100%", backgroundColor: "inherit", color: "inherit" }}
        >
          {message}
        </Alert>
      </StyledSnackbar>
    );
  }
  
  export default Toast;