const Logout = () => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.54665 14.6673H4.28932C2.65998 14.6673 1.33398 13.3413 1.33398 11.7107V4.29132C1.33398 2.66065 2.65998 1.33398 4.28932 1.33398H7.53932C9.16998 1.33398 10.4967 2.66065 10.4967 4.29132V4.91265C10.4967 5.18865 10.2727 5.41265 9.99665 5.41265C9.72065 5.41265 9.49665 5.18865 9.49665 4.91265V4.29132C9.49665 3.21132 8.61865 2.33398 7.53932 2.33398H4.28932C3.21132 2.33398 2.33398 3.21132 2.33398 4.29132V11.7107C2.33398 12.79 3.21132 13.6673 4.28932 13.6673H7.54665C8.62132 13.6673 9.49665 12.7927 9.49665 11.718V11.0893C9.49665 10.8133 9.72065 10.5893 9.99665 10.5893C10.2727 10.5893 10.4967 10.8133 10.4967 11.0893V11.718C10.4967 13.3447 9.17265 14.6673 7.54665 14.6673"
        fill="black"
      />
      <mask
        id="mask0_2086_14255"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="5"
        y="7"
        width="11"
        height="2"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.99805 7.5H15.0253V8.5H5.99805V7.5Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_2086_14255)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.5254 8.5H6.49805C6.22205 8.5 5.99805 8.276 5.99805 8C5.99805 7.724 6.22205 7.5 6.49805 7.5H14.5254C14.8014 7.5 15.0254 7.724 15.0254 8C15.0254 8.276 14.8014 8.5 14.5254 8.5"
          fill="black"
        />
      </g>
      <mask
        id="mask1_2086_14255"
        style={{ maskType: "alpha" }}
        maskUnits="userSpaceOnUse"
        x="12"
        y="5"
        width="4"
        height="6"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.0732 5.55664H15.0248V10.4438H12.0732V5.55664Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_2086_14255)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5731 10.4438C12.4451 10.4438 12.3164 10.3951 12.2191 10.2965C12.0244 10.1005 12.0251 9.78447 12.2204 9.58981L13.8164 7.99981L12.2204 6.41047C12.0251 6.21581 12.0237 5.89981 12.2191 5.70381C12.4137 5.50781 12.7297 5.50781 12.9257 5.70247L14.8777 7.64581C14.9724 7.73914 15.0251 7.86714 15.0251 7.99981C15.0251 8.13247 14.9724 8.26047 14.8777 8.35381L12.9257 10.2978C12.8284 10.3951 12.7004 10.4438 12.5731 10.4438"
          fill="black"
        />
      </g>
    </svg>
  );
  
  export default Logout;
  