import "./Form.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { createTheme, FormLabel, ThemeProvider } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dropdown from "../Dropdown/Dropdown";
import TextGroup from "../TextGroup/TextGroup";
import TableInput from "../TableInput/TableInput";
import FileUpload from "../FileUpload/FileUpload";
import CheckboxGroup from "../CheckboxGroup/CheckboxGroup";
import { useRef, useState, useEffect } from "react";

export default function Form(props) {
  const formAnswers = useRef([]);
  const [error, setError] = useState(false);
  const [formAnswersState, setFormAnswersState] = useState([
    ...formAnswers.current,
  ]);

  const theme = createTheme({
    typography: {
      fontFamily: ["ibm plex sans", "Roboto"].join(","),
      fontSize: 13,
      fontWeight: 400,
    },
  });

  const mentor_key = [
    "claudia-ortega",
    "ana-diz",
    "thatiana-villanueva",
    "lilian-saavedra",
    "monica-ferrara",
    "lucia-salas",
  ];

  useEffect(() => {
    setFormAnswersState([...formAnswers.current]);
  }, [formAnswers.current]);

  const blockId = props.block;
  const userId = props.user;

  if (formAnswers.current.length > 0) {
    // If formAnswers has data, and the id_block is different, reset the array
    if (formAnswers.current[0].block_id !== blockId) {
      //console.log("different block")
      formAnswers.current = [];
    }
  }

  const singleCallback = (answer) => {
    console.log("answer", answer);
    // Check if question ID is present in answers array

    const index = formAnswers.current.findIndex(
      (element) => element.id_question === answer.id_question
    );

    if (index > -1) {
      // Already exists

      formAnswers.current[index].answer = answer.answer;

      props.callback(formAnswers.current);
      setFormAnswersState([...formAnswers.current]); // Trigger re-render
    } else {
      // New answer

      formAnswers.current.push(answer);
      setFormAnswersState([...formAnswers.current]); // Trigger re-render
    }

    //if (formAnswers.current.length === questions.length) {
    // Form is complete

    props.callback(formAnswers.current);

    //}
  };

  const onAnswer = (event, type, id, label, key_validation) => {
    if (error === true) setError(false);

    // Check if validation is required
    if (key_validation && key_validation === "true") {
      // Check if the answer is on the mentor list
      if (!mentor_key.includes(event.target.value)) {
        // Answer is not valid
        setError(true);
        return;
      }
    }

    // 1. Check if answer already exists
    const index = formAnswers.current.findIndex(
      (element) => element.id_question === id
    );

    if (index > -1) {
      // Answer has been captured, replace value
      formAnswers.current[index].answer = event.target.value;
      //console.log("formAnswers", formAnswers.current)
      props.callback(formAnswers.current);
      setFormAnswersState([...formAnswers.current]); // Trigger re-render
    } else {
      // Answer hasn't been captured, add answer object to array
      formAnswers.current.push({
        block_id: blockId,
        user_id: userId,
        answer: event.target.value,
        question_type: type,
        id_question: id,
        question: label,
      });

      // Form is complete
      props.callback(formAnswers.current);
      setFormAnswersState([...formAnswers.current]); // Trigger re-render
    }
  };

  const onCheckbox = (event, type, id, label) => {
    console.log("Checkbox event triggered");
    console.log("Event value:", event.target.value);
    console.log("Event checked status:", event.target.checked);
    console.log(
      "Current formAnswers before modification:",
      formAnswers.current
    );

    const uniqueAnswers = Array.from(
      new Map(
        formAnswers.current.map((answer) => [answer.id_question, answer])
      ).values()
    );

    formAnswers.current = uniqueAnswers;

    // Encuentra el índice de la respuesta correspondiente
    const index = formAnswers.current.findIndex(
      (element) => element.id_question === id
    );

    console.log("Index found for id_question:", index);
    console.log(
      "Current formAnswers before modification:",
      JSON.stringify(formAnswers.current, null, 2)
    );

    if (index > -1) {
      // Respuesta ya existente
      if (event.target.checked) {
        // Agrega el valor si no está presente
        if (!formAnswers.current[index].answer.includes(event.target.value)) {
          formAnswers.current[index].answer.push(event.target.value);
          console.log(`Added value "${event.target.value}" to answer.`);
        }
      } else {
        // Elimina el valor desmarcado
        const checkboxIndex = formAnswers.current[index].answer.indexOf(
          event.target.value
        );
        if (checkboxIndex > -1) {
          formAnswers.current[index].answer.splice(checkboxIndex, 1);
          console.log(`Removed value "${event.target.value}" from answer.`);
        }

        // Elimina la respuesta si queda vacía
        if (formAnswers.current[index].answer.length === 0) {
          console.log(
            `Answer array is empty. Removing answer for question ID ${id}.`
          );
          formAnswers.current.splice(index, 1);
        }
      }
    } else {
      // Nueva respuesta
      formAnswers.current.push({
        block_id: blockId,
        user_id: userId,
        answer: [event.target.value],
        question_type: type,
        id_question: id,
        question: label,
      });
      console.log(
        "New answer added:",
        formAnswers.current[formAnswers.current.length - 1]
      );
    }

    // Filtrar duplicados después de cada actualización
    formAnswers.current = formAnswers.current.filter(
      (answer, idx, self) =>
        idx === self.findIndex((a) => a.id_question === answer.id_question)
    );

    console.log(
      "Updated formAnswers after handling checkbox:",
      JSON.stringify(formAnswers.current, null, 2)
    );

    // Llama al callback para sincronizar el estado
    props.callback([...formAnswers.current]);
  };

  const questions = [];
  //console.log("formAnswersState", formAnswersState)

  //if (props.survey[0].answer) {
  props.survey.forEach((q) => {
    if (q != null && q.answer && q.answer !== null) {
      let options;
      switch (q.question_type) {
        case "radio_single_choice":
          options = [];
          q.choices.forEach((op) => {
            if (op.category_dependency_input !== null) {
              const formAnswersStateVar = [
                ...new Set([...props.survey, ...formAnswersState]),
              ];
              // Your existing checks and logs
              if (
                formAnswersStateVar.find(
                  (element) =>
                    element.id_question === op.category_dependency_input
                )?.answer !== op.cateogry_dependency_answer
              ) {
                return;
              }
            }
            const hasNewAnswer = formAnswersState.find(
              (element) =>
                element.id_question === q.id_question &&
                element.answer !== q.answer
            );
            if (q.answer === op.choice_label && !hasNewAnswer) {
              // Selected
              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={<Radio checked />}
                  label={op.choice_label}
                  value={op.choice_label}
                />
              );
            } else {
              options.push(
                <FormControlLabel
                  control={<Radio />}
                  label={op.choice_label}
                  value={op.choice_label}
                />
              );
            }
          });
          questions.push(
            <FormControl component="fieldset">
              {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
            
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <RadioGroup
                onChange={(event) => {
                  //console.log("event", event)
                  onAnswer(
                    event,
                    q.question_type,
                    q.id_question,
                    q.question_label
                  );
                }}
              >
                {options}
              </RadioGroup>
            </FormControl>
          );
          break;
        case "text":
          questions.push(
            <FormControl>
              {q.question_title && (
                <label className={"questionTitle"}>
                  <b>{q.question_title}</b>
                </label>
              )}
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
              <FormLabel component="span" sx={{}}>{q.question_label}</FormLabel>
              <TextField
                id={q.id_question}
                variant="outlined"
                defaultValue={q.answer}
                onChange={(event) =>
                  onAnswer(
                    event,
                    q.question_type,
                    q.id_question,
                    q.question_label
                  )
                }
              />
            </FormControl>
          );
          break;
        case "text-small":
          questions.push(
            <FormControl sx={{ width: "40ch" }}>
              {q.question_title && (
                <label className={"questionTitle"}>
                  <b>{q.question_title}</b>
                </label>
              )}
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <TextField
                id={q.id_question}
                variant="outlined"
                defaultValue={q.answer}
                onChange={(event) =>
                  onAnswer(
                    event,
                    q.question_type,
                    q.id_question,
                    q.question_label
                  )
                }
              />
            </FormControl>
          );
          break;
        case "text-large":
          questions.push(
            <FormControl>
              {q.question_title && (
                <label className={"questionTitle"}>
                  <b>{q.question_title}</b>
                </label>
              )}
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <TextField
                id={q.id_question}
                variant="outlined"
                multiline
                defaultValue={q.answer}
                onChange={(event) =>
                  onAnswer(
                    event,
                    q.question_type,
                    q.id_question,
                    q.question_label
                  )
                }
              />
            </FormControl>
          );
          break;
        case "textarea":
          questions.push(
            <FormControl>
              {q.question_title && (
                <label className={"questionTitle"}>
                  <b>{q.question_title}</b>
                </label>
              )}
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <TextField
                id={q.id_question}
                variant="outlined"
                multiline
                defaultValue={q.answer}
                onChange={(event) =>
                  onAnswer(
                    event,
                    q.question_type,
                    q.id_question,
                    q.question_label
                  )
                }
              />
            </FormControl>
          );
          break;
        case "file_upload":
          questions.push(
            <FormControl>
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <FileUpload
                answer={q.answer}
                fileCallback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                  id_question: q.id_question,
                  question: q.question_label,
                }}
              />
            </FormControl>
          );
          break;
        case "radio_range":
          if (q?.choices) {
            questions.push(
              <FormControl>
                <FormLabel>{q.question_label}</FormLabel>
                <RadioGroup
                  row
                  defaultValue={+q.answer}
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label
                    )
                  }
                >
                  {q.choices.map((_, i) => (
                    <FormControlLabel
                      key={i}
                      value={i + 1}
                      control={<Radio />}
                      label={i + 1}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            );
          } else {
            if (q.length === 5) {
              questions.push(
                <FormControl>
                  <FormLabel>{q.question_label}</FormLabel>
                  <RadioGroup
                    row
                    defaultValue={+q.answer}
                    onChange={(event) =>
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      )
                    }
                  >
                    {[...Array(5)].map((_, i) => (
                      <FormControlLabel
                        key={i}
                        value={i + 1}
                        control={<Radio />}
                        label={i + 1}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
            } else {
              questions.push(
                <FormControl>
                  <FormLabel>{q.question_label}</FormLabel>
                  <RadioGroup
                    row
                    defaultValue={+q.answer}
                    onChange={(event) =>
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      )
                    }
                  >
                    {[...Array(10)].map((_, i) => (
                      <FormControlLabel
                        key={i}
                        value={i + 1}
                        control={<Radio />}
                        label={i + 1}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
            }
          }

          break;
        case "checkbox_multiple_choice":
          const ans = JSON.parse(q.answer);
          formAnswers.current.push({
            block_id: blockId,
            user_id: userId,
            answer: ans,
            question_type: q.question_type,
            id_question: q.id_question,
            question: q.question_label,
          });

          options = [];
          q.choices.forEach((op) => {
            if (q.answer.includes(op.choice_label)) {
              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={
                    <Checkbox
                      value={op.choice_label}
                      defaultChecked
                      onChange={(event) => {
                        onCheckbox(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        );
                      }}
                    />
                  }
                  label={op.choice_label}
                />
              );
            } else {
              options.push(
                <FormControlLabel
                  control={
                    <Checkbox
                      value={op.choice_label}
                      onChange={(event) => {
                        onCheckbox(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        );
                      }}
                    />
                  }
                  label={op.choice_label}
                />
              );
            }
          });
          questions.push(
            <FormControl component="fieldset">
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <FormGroup>{options}</FormGroup>
            </FormControl>
          );

          break;
        case "checkbox_columns":
          options = [];
          q.choices.forEach((op) => {
            options.push(
              <FormControlLabel
                index={q.id_question}
                control={<Checkbox />}
                label={op.choice_label}
              />
            );
          });
          questions.push(
            <FormControl component="fieldset">
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <FormGroup className="checkbox-columns">{options}</FormGroup>
            </FormControl>
          );
          break;
        case "select":
          questions.push(
            <>
              {q.question_title && (
                <label className={"questionTitle"}>
                  <b>{q.question_title}</b>
                </label>
              )}
              <Dropdown
                id={q.id_question}
                label={q.question_label}
                options={q.choices}
                selectCallback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                }}
                answer={q.answer}
                currentAnswers={formAnswers.current}
              />
            </>
          );
          break;
        case "text_group":
          questions.push(
            <TextGroup
              min={q.min}
              max={q.max}
              id={q.id_question}
              label={q.question_label}
            />
          );
          break;
        case "table":
          questions.push(
            <TableInput
              answer={q.answer}
              rows={q.rows}
              callback={singleCallback}
              userData={{
                id: userId,
                block: blockId,
                id_question: q.id_question,
                question: q.question_label,
              }}
            />
          );
          break;
        case "checkbox_group":
          questions.push(
            <CheckboxGroup
              limit={q.max}
              list={q.list}
              callback={singleCallback}
              answer={q.answer}
              userData={{
                id: userId,
                block: blockId,
                id_question: q.id_question,
                question: q.id_question,
              }}
            />
          );
          break;
        default:
          questions.push(<p>Unknown question type: {q.question_type}</p>);
          break;
      }
    } else if (q !== null) {
      let options;
      switch (q.question_type) {
        case "radio_single_choice":
          options = [];
          q.choices.forEach((op) => {
            if (op.category_dependency_input !== null) {
              // Your existing checks and logs
              if (
                formAnswersState.find(
                  (element) =>
                    element.id_question === op.category_dependency_input
                )?.answer !== op.cateogry_dependency_answer
              ) {
                return;
              }
            }

            options.push(
              <FormControlLabel
                index={q.id_question}
                control={<Radio />}
                label={op.choice_label}
                value={op.choice_label}
              />
            );
          });

          if (options.length === 0) {
            options.push(
              <FormControlLabel
                index={q.id_question}
                control={<Radio />}
                label={"Selecciona un objetivo para desplegar las opciones."}
                value={"Selecciona un objetivo para desplegar las opciones."}
                disabled
              />
            );
          }
          questions.push(
            <FormControl component="fieldset">
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <RadioGroup
                onChange={(event) =>
                  onAnswer(
                    event,
                    q.question_type,
                    q.id_question,
                    q.question_label
                  )
                }
              >
                {options}
              </RadioGroup>
            </FormControl>
          );
          break;
        case "text":
          questions.push(
            <FormControl>
              {q.question_title && (
                <label className={"questionTitle"}>
                  <b>{q.question_title}</b>
                </label>
              )}
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <TextField
                id={q.id_question}
                placeholder={
                  q.question_placeholder
                    ? q.question_placeholder
                    : "Escribe aquí..."
                }
                variant="outlined"
                onChange={(event) =>
                  onAnswer(
                    event,
                    q.question_type,
                    q.id_question,
                    q.question_label,
                    q.key_validation
                  )
                }
                defaultValue={""}
              />
            </FormControl>
          );
          break;
        case "text-small":
          questions.push(
            <FormControl sx={{ width: "40ch" }}>
              {q.question_title && (
                <label className={"questionTitle"}>
                  <b>{q.question_title}</b>
                </label>
              )}
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <TextField
                id={q.id_question}
                placeholder={
                  q.question_placeholder
                    ? q.question_placeholder
                    : "Escribe aquí..."
                }
                variant="outlined"
                onChange={(event) =>
                  onAnswer(
                    event,
                    q.question_type,
                    q.id_question,
                    q.question_label,
                    q.key_validation
                  )
                }
                defaultValue={""}
              />
            </FormControl>
          );
          break;
        case "text-large":
          questions.push(
            <FormControl>
              {q.question_title && (
                <label className={"questionTitle"}>
                  <b>{q.question_title}</b>
                </label>
              )}
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <TextField
                id={q.id_question}
                placeholder={
                  q.question_placeholder
                    ? q.question_placeholder
                    : "Escribe aquí..."
                }
                variant="outlined"
                multiline
                onChange={(event) =>
                  onAnswer(
                    event,
                    q.question_type,
                    q.id_question,
                    q.question_label,
                    q.key_validation
                  )
                }
                defaultValue={""}
              />
            </FormControl>
          );
          break;
        case "textarea":
          questions.push(
            <FormControl>
              {q.question_title && (
                <label className={"questionTitle"}>
                  <b>{q.question_title}</b>
                </label>
              )}
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <TextField
                id={q.id_question}
                placeholder={
                  q.question_placeholder
                    ? q.question_placeholder
                    : "Escribe aquí..."
                }
                variant="outlined"
                multiline
                onChange={(event) =>
                  onAnswer(
                    event,
                    q.question_type,
                    q.id_question,
                    q.question_label,
                    q.key_validation
                  )
                }
                defaultValue={""}
              />
            </FormControl>
          );
          break;
        case "file_upload":
          questions.push(
            <FormControl>
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <FileUpload
                fileCallback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                  id_question: q.id_question,
                  question: q.question_label,
                }}
              />
            </FormControl>
          );
          break;
        case "radio_range":
          if (q?.choices) {
            questions.push(
              <FormControl>
                <FormLabel>{q.question_label}</FormLabel>
                <RadioGroup
                  row
                  defaultValue={+q.answer}
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label
                    )
                  }
                >
                  {q.choices.map((choice, i) => (
                    <FormControlLabel
                      key={choice.id}
                      value={choice.choice_label}
                      control={<Radio />}
                      label={choice.choice_label}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            );
            break;
          } else {
            if (q.length === 5) {
              questions.push(
                <FormControl>
                  <FormLabel>{q.question_label}</FormLabel>
                  <RadioGroup
                    row
                    defaultValue={+q.answer}
                    onChange={(event) =>
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      )
                    }
                  >
                    {[...Array(5)].map((_, i) => (
                      <FormControlLabel
                        key={i}
                        value={i + 1}
                        control={<Radio />}
                        label={i + 1}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
            } else {
              questions.push(
                <FormControl>
                  <FormLabel>{q.question_label}</FormLabel>
                  <RadioGroup
                    row
                    defaultValue={+q.answer}
                    onChange={(event) =>
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      )
                    }
                  >
                    {[...Array(10)].map((_, i) => (
                      <FormControlLabel
                        key={i}
                        value={i + 1}
                        control={<Radio />}
                        label={i + 1}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
            }
          }

          break;
        case "checkbox_multiple_choice":
          options = [];
          q.choices.forEach((op) => {
            options.push(
              <FormControlLabel
                index={q.id_question}
                control={
                  <Checkbox
                    value={op.choice_label}
                    onChange={(event) => {
                      onCheckbox(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      );
                    }}
                  />
                }
                label={op.choice_label}
              />
            );
          });
          questions.push(
            <FormControl component="fieldset">
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <FormGroup>{options}</FormGroup>
            </FormControl>
          );
          break;
        case "checkbox_columns":
          options = [];
          q.choices.forEach((op) => {
            options.push(
              <FormControlLabel
                index={q.id_question}
                control={<Checkbox />}
                label={op.choice_label}
              />
            );
          });
          questions.push(
            <FormControl component="fieldset">
                {q.question_description && (
                <label className={"questionTitle"}>
                  <b>{q.question_description}</b>
                </label>
              )}
               <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
              <FormGroup className="checkbox-columns">{options}</FormGroup>
            </FormControl>
          );
          break;
        case "select":
          questions.push(
            <>
              {q.question_title && (
                <label className={"questionTitle"}>
                  <b>{q.question_title}</b>
                </label>
              )}
              <Dropdown
                id={q.id_question}
                label={q.question_label}
                options={q.choices}
                selectCallback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                }}
                currentAnswers={formAnswers.current}
              />
            </>
          );
          break;
        case "text_group":
          questions.push(
            <TextGroup
              min={q.min}
              max={q.max}
              id={q.id_question}
              label={q.question_label}
            />
          );
          break;
        case "table":
          questions.push(
            <TableInput
              rows={q.rows}
              callback={singleCallback}
              userData={{
                id: userId,
                block: blockId,
                id_question: q.id_question,
                question: q.question_label,
              }}
            />
          );
          break;
        case "checkbox_group":
          questions.push(
            <CheckboxGroup
              limit={q.max}
              list={q.list}
              callback={singleCallback}
              userData={{
                id: userId,
                block: blockId,
                id_question: q.id_question,
                question: q.id_question,
              }}
            />
          );
          break;
        default:
          questions.push(<p>Unknown question type: {q.question_type}</p>);
          break;
      }
    }
  });
  /*} else {
    props.survey.forEach((q) => {
      if (q != null) {
        let options;
        switch (q.question_type) {
          case "radio_single_choice":
            options = [];
            q.choices.forEach((op) => {
              if (op.category_dependency_input !== null) {
                // Your existing checks and logs
                if (
                  formAnswersState.find(
                    (element) =>
                      element.id_question === op.category_dependency_input
                  )?.answer !== op.cateogry_dependency_answer
                ) {
                  return;
                }
              }

              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={<Radio />}
                  label={op.choice_label}
                  value={op.choice_label}
                />
              );
            });

            if (options.length === 0) {
              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={<Radio />}
                  label={"Selecciona un objetivo para desplegar las opciones."}
                  value={"Selecciona un objetivo para desplegar las opciones."}
                  disabled
                />
              );
            }
            questions.push(
              <FormControl component="fieldset">
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <RadioGroup
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label
                    )
                  }
                >
                  {options}
                </RadioGroup>
              </FormControl>
            );
            break;
          case "text":
            questions.push(
              <FormControl>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "text-small":
            questions.push(
              <FormControl sx={{ width: "40ch" }}>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "text-large":
            questions.push(
              <FormControl>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  multiline
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "textarea":
            questions.push(
              <FormControl>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <TextField
                  id={q.id_question}
                  placeholder={
                    q.question_placeholder
                      ? q.question_placeholder
                      : "Escribe aquí..."
                  }
                  variant="outlined"
                  multiline
                  onChange={(event) =>
                    onAnswer(
                      event,
                      q.question_type,
                      q.id_question,
                      q.question_label,
                      q.key_validation
                    )
                  }
                  defaultValue={""}
                />
              </FormControl>
            );
            break;
          case "file_upload":
            questions.push(
              <FileUpload
                fileCallback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                  id_question: q.id_question,
                  question: q.question_label,
                }}
              />
            );
            break;
          case "radio_range":
            if (q?.choices) {
              questions.push(
                <FormControl>
                  <FormLabel>{q.question_label}</FormLabel>
                  <RadioGroup
                    row
                    defaultValue={+q.answer}
                    onChange={(event) =>
                      onAnswer(
                        event,
                        q.question_type,
                        q.id_question,
                        q.question_label
                      )
                    }
                  >
                    {q.choices.map((choice, i) => (
                      <FormControlLabel
                        key={choice.id}
                        value={choice.choice_label}
                        control={<Radio />}
                        label={choice.choice_label}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              );
              break;
            } else {
              if (q.length === 5) {
                questions.push(
                  <FormControl>
                    <FormLabel>{q.question_label}</FormLabel>
                    <RadioGroup
                      row
                      defaultValue={+q.answer}
                      onChange={(event) =>
                        onAnswer(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        )
                      }
                    >
                      {[...Array(5)].map((_, i) => (
                        <FormControlLabel
                          key={i}
                          value={i + 1}
                          control={<Radio />}
                          label={i + 1}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                );
              } else {
                questions.push(
                  <FormControl>
                    <FormLabel>{q.question_label}</FormLabel>
                    <RadioGroup
                      row
                      defaultValue={+q.answer}
                      onChange={(event) =>
                        onAnswer(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        )
                      }
                    >
                      {[...Array(10)].map((_, i) => (
                        <FormControlLabel
                          key={i}
                          value={i + 1}
                          control={<Radio />}
                          label={i + 1}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                );
              }
            }

            break;
          case "checkbox_multiple_choice":
            options = [];
            q.choices.forEach((op) => {
              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={
                    <Checkbox
                      value={op.choice_label}
                      onChange={(event) => {
                        onCheckbox(
                          event,
                          q.question_type,
                          q.id_question,
                          q.question_label
                        );
                      }}
                    />
                  }
                  label={op.choice_label}
                />
              );
            });
            questions.push(
              <FormControl component="fieldset">
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <FormGroup>{options}</FormGroup>
              </FormControl>
            );
            break;
          case "checkbox_columns":
            options = [];
            q.choices.forEach((op) => {
              options.push(
                <FormControlLabel
                  index={q.id_question}
                  control={<Checkbox />}
                  label={op.choice_label}
                />
              );
            });
            questions.push(
              <FormControl component="fieldset">
                 <FormLabel component="span" sx={{whiteSpace: "pre-line"}}>{q.question_label}</FormLabel>
                <FormGroup className="checkbox-columns">{options}</FormGroup>
              </FormControl>
            );
            break;
          case "select":
            questions.push(
              <>
                {q.question_title && (
                  <label className={"questionTitle"}>
                    <b>{q.question_title}</b>
                  </label>
                )}
                <Dropdown
                  id={q.id_question}
                  label={q.question_label}
                  options={q.choices}
                  selectCallback={singleCallback}
                  userData={{
                    id: userId,
                    block: blockId,
                  }}
                  currentAnswers={formAnswers.current}
                />
              </>
            );
            break;
          case "text_group":
            questions.push(
              <TextGroup
                min={q.min}
                max={q.max}
                id={q.id_question}
                label={q.question_label}
              />
            );
            break;
          case "table":
            questions.push(
              <TableInput
                rows={q.rows}
                callback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                  id_question: q.id_question,
                  question: q.question_label,
                }}
              />
            );
            break;
          case "checkbox_group":
            questions.push(
              <CheckboxGroup
                limit={q.max}
                list={q.list}
                callback={singleCallback}
                userData={{
                  id: userId,
                  block: blockId,
                  id_question: q.id_question,
                  question: q.id_question,
                }}
              />
            );
            break;
          default:
            questions.push(<p>Unknown question type: {q.question_type}</p>);
            break;
        }
      }
    });
  }*/

  return (
    <ThemeProvider theme={theme}>
      <div className="form">
        <form>{questions}</form>
        <div className="error">
          {error &&
            "La clave del mentor es erronea, por favor verifica la clave con tu mento."}
        </div>
      </div>
    </ThemeProvider>
  );
}
