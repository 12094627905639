/**
 * Appends a new message to the current chat message list.
 *
 * This function takes a message object and appends it to the current message list.
 * The callback function is invoked with the updated message list.
 *
 * @param {string} message - The content of the message.
 * @param {{ author: string, error: boolean, id: string }} options - An object containing additional options.
 * @param {function} callback - A callback function that receives the updated message list.
 */
export const appendMessage = (message, { author, error = false, id } = {}, callback) => {
    callback((currentMessages) => [
        ...currentMessages,
        { content: message, author, error, id },
    ]);
};

/**
 * Generates a unique identifier for a message based on the author and existing messages.
 *
 * This function takes the author's role and an array of existing messages and generates a unique ID.
 * The ID format is "{author}{number}" where the number is incremented based on the existing message IDs for the same author.
 *
 * @param {string} author - The role of author of the message.
 * @param {object[]} messages - An array of existing message objects.
 * @returns {string} - The generated unique identifier for the message.
 */
export const generateId = (author, messages) => {
    // Early return if empty array
    if (messages.length === 0) return `${author}1`;
    // Array of messages IDs
    const idArray = messages.map((m) => Number(m.id.replace(m.author, "")));
    // Next ID is current max + 1
    return `${author}${Math.max(...idArray) + 1}`;
};

/**
 * Sets the user ID in localStorage if it is not already set.
 *
 * This function checks if a user ID exists in localStorage under the key "userId".
 * If the ID is not found, it generates a new ID by prepending "sandbox-" to the user's email address and saves it to localStorage.
 *
 * @param {object} user - The user object containing an "email" property.
 */
export const setUserId = (user) => {
    const userId = localStorage.getItem("userId");

    if (!userId) {
        const newUserId = `sandbox-${user.email}`;
        localStorage.setItem("userId", newUserId);
    }
};

/**
 * Creates a user interaction by sending a user message, receiving a bot response, and appending both to the message list.
 *
 * This function handles the entire interaction flow, including sending the user message, getting the bot response, and updating the message list.
 * If an error occurs during the bot response, an error message is appended instead.
 *
 * @param {string} userMessage - The user's message.
 * @param {object[]} chatMessages - The current list of chat messages.
 * @param {object} callback - An object containing callback functions for resetting, updating, and getting the bot response.
 * @returns {Promise<void>} - Resolves when the interaction is complete or rejects if an error occurs.
 */
export const createUserInteraction = async (userMessage, chatMessages, callback) => {
    // Reset message state
    callback.resetMessage("");

    // Append user message
    const userMessageOptions = { author: "user", id: generateId("user", chatMessages) };
    appendMessage(userMessage, userMessageOptions, callback.updateMessages);

    const userId = localStorage.getItem("userId");

    // Get bot response
    const [botResponse, error] = await callback.getBotResponse(userMessage, userId);

    // Handle error or empty response
    if (error || botResponse === null) {
        const errorMessage = "Parece que hay un error, vuelve a intentarlo más tarde";
        const errorOptions = { author: "bot", error: true, id: generateId("bot", chatMessages) };
        return appendMessage(errorMessage, errorOptions, callback.updateMessages);
    }

    // Append bot response
    const botMessageOptions = { author: "bot", id: generateId("bot", chatMessages) };
    appendMessage(botResponse, botMessageOptions, callback.updateMessages);
};

/**
 * Deletes a specific bot error message and its corresponding user message on demand.
 *
 * This function takes the event object, the error message ID, and the current chat messages as arguments.
 * It filters out the error message and its corresponding user message (assuming the user message ID follows a specific format) from the chat messages list.
 * It then updates the chat state with the filtered messages.
 *
 * @param {Event} e - The event object (used to prevent default behavior).
 * @param {string} errorMessageId - The ID of the bot error message to be deleted.
 * @param {object[]} chatMessages - The current list of chat messages.
 * @param {object} callback - An object containing callback functions for setting deleting state and updating the message list.
 * @returns {Promise<void>} - Resolves when the deletion is complete.
 */
export const deleteErrorMessages = async (e, errorMessageId, chatMessages, callback) => {
    e.preventDefault();

    // Disable chatbox input and send button
    callback.deletingMessages(true);

    // Extract user message ID based on naming convention
    const userMessageId = errorMessageId.replace("bot", "user");

    // Filter chat messages to remove error messages
    const chatWithoutErrors = chatMessages.filter(
        (message) => message.id !== errorMessageId && message.id !== userMessageId
    );

    // Update deleting state
    callback.deletingMessages(false);

    // Update chat state with filtered messages
    callback.updateMessages(chatWithoutErrors);
};

/**
 * Retries sending a message associated with a given error message ID.
 *
 * This function takes the event object and the error message ID as arguments.
 * It extracts the corresponding user message ID from the error message ID (assuming a specific format).
 * Then, it finds the user message in the chat messages list and retries sending its content using the `createUserInteraction` function.
 *
 * @param {Event} e - The event object (used to prevent default behavior).
 * @param {string} errorMessageId - The ID of the bot error message.
 * @param {object[]} chatMessages - The current list of chat messages.
 * @returns {Promise<void>} - Resolves when the retry attempt is complete.
 */
export const retrySendMessage = async (e, errorMessageId, chatMessages, callback) => {
    e.preventDefault();

    // Extract user message ID based on naming convention
    const userMessageId = errorMessageId.replace("bot", "user");

    // Find the user message associated with the error
    const foundUserMessage = chatMessages.find(
        (userMessage) => userMessage.id === userMessageId
    );

    // Resend message content if user message found
    if (foundUserMessage !== null) {
        await createUserInteraction(foundUserMessage.content, chatMessages, callback);
    } else {
        console.warn("Unable to find user message for retry:", errorMessageId);
    }
};
