import React from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

const CalendarToolBar = (toolbar) => {
    const goToBack = () => {
        toolbar.onNavigate("PREV");
    };

    const goToNext = () => {
        toolbar.onNavigate("NEXT");
    };

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "20px",
                position: "relative", // Necesario para centrar el título
            }}
        >
            {/* Botón izquierdo */}
            <button
                onClick={goToBack}
                style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "30px",
                    position: "absolute", // Mantener el botón en la izquierda
                    left: 0,
                }}
            >
                <ChevronLeftIcon />
            </button>

            {/* Título centrado */}
            <span
                style={{
                    fontSize: "2rem",
                    fontWeight: "600",
                    margin: "0 auto", // Centro automático horizontal
                }}
            >
               {capitalizeFirstLetter(toolbar.label)}
            </span>

            {/* Botón derecho */}
            <button
                onClick={goToNext}
                style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    fontSize: "30px",
                    position: "absolute", 
                    right: 0,
                }}
            >
                <ChevronRightIcon />
            </button>
        </div>
    );
};

export default CalendarToolBar;
