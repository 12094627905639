import "./NavBar.css";
import logo from "../../assets/pivotea-logo.png";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LogOut from './Logout.jsx'
import Profile from './User.jsx'
import Avatar from "@mui/material/Avatar";
import Popover from "@mui/material/Popover";
import { useState, useEffect } from "react";

import Cookies from "js-cookie";

import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export default function NavBar(props) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    authUser,
    setAuthUser,
    isLoggedIn,
    setIsLoggedIn,
    hasPurchase,
    verifyPurchase,
    purchaseStatus,
    lastPurchase
  } = useAuth();
  const [hidden, setHidden] = useState("hidden");

  const onOpen = () => {
    setHidden("");
  };

  const onClose = () => {
    setHidden("hidden");
  };

  const onLogout = () => {
    setHidden("hidden");
    handleClose();
    Cookies.remove("authUser");
    setIsLoggedIn(false);
    setAuthUser(null);
    navigate("/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (isLoggedIn && authUser) {
      verifyPurchase();
    }
  }, [isLoggedIn, authUser, verifyPurchase]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (!isLoggedIn) {
      setHidden("hidden");
    }
  }, [isLoggedIn]);

  return (
    <div className="navbar">
      <a href="/">
        <img src={logo} className="logo" alt="Pivotea" />
      </a>
      <div className="menu-items">
        {isLoggedIn ? (
          <>
            <nav>
              <ul>
                {/* Escenario 1: El usuario tiene una compra activa */}
                {hasPurchase && (
                  <>
                    <li>
                      <a href="/progreso">Vocacionamiento</a>
                    </li>
                    <li>
                      <a href="https://courses.skillingcenter.com" target="_blank">Formación</a>
                    </li>
                    <li>
                      <a href="https://chat.whatsapp.com/Ffdejw3Sx6iKboxL1NmlMp" target="_blank">Acompañamiento</a>
                    </li>
                    <li>
                      <a href="/gym-profesional">Gym Profesional</a>
                    </li>
                    {purchaseStatus === "processing" && (
                      <li>
                        <a href="/checkout">¡Comprar experiencia!</a>
                      </li>
                    )}
                  </>
                )}

                {/* Escenario 2: El usuario no tiene compra activa y el estado de compra es "processing" */}
                {!hasPurchase && lastPurchase?.status === "processing" && (
                  <li>
                    <a href="/checkout/return">¡Comprar experiencia!</a>
                  </li>
                )}

                {/* Escenario 3: El usuario no tiene compra activa y el estado de compra es "null" */}
                {!hasPurchase && lastPurchase?.status === null && (
                  <li>
                    <a href="/checkout">¡Comprar experiencia!</a>
                  </li>
                )}

                {/* Escenario 4: Estado de compra iniciado */}
                {purchaseStatus === "started" && (
                  <li>
                    <a href="/checkout">¡Comprar experiencia!</a>
                  </li>
                )}

                {/* Escenario 5: El usuario está registrado pero no tiene compra */}
                {isLoggedIn && !hasPurchase && lastPurchase?.status === null && (
                  <li>
                    <a href="/checkout">¡Comprar experiencia!</a>
                  </li>
                )}

                {/* Escenario 6: Nuevo usuario*/}
                {isLoggedIn &&
                  !hasPurchase &&
                  (lastPurchase?.status === null || lastPurchase?.status === undefined) && (
                    <li>
                      <a href="/checkout">¡Comprar experiencia!</a>
                    </li>
                  )}

              </ul>
            </nav>
            <div className="divider"></div>
            <a aria-describedby={id} onClick={handleClick} className="user-info">
              <div className="avatar-container">
                <Avatar
                  sx={{
                    bgcolor: "var(--secondary-dark)",
                    color: "var(--primary)",
                    cursor: "pointer",
                  }}
                  alt={authUser?.name || "-"}
                >
                  {authUser?.name ? authUser.name[0] : "-"}
                </Avatar>
                <p className="user-name">{authUser?.name || "Predeterminado"}</p>
              </div>
            </a>
          </>
        ) : (
          <>
            <a className="nav-link" href="/login">
              Iniciar sesión
            </a>
            <a className="nav-link" href="/signup">
              Registro
            </a>
          </>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {hasPurchase && (
            <>
              <a
                href="/perfil"
                className="logout-mobile"
                style={{ padding: "0.5rem", cursor: "pointer" }}
              >
                <Profile width="50" height="50" /> Mi perfil
              </a>
            </>
          )}

          {/* 
          {isLoggedIn &&
            authUser?.current_module ===
            "ffc340f4-eea3-4ab5-8430-d65fb062a6e8" && (
              <a
                href="/proyecto-de-aprendizaje"
                className="logout-mobile"
                style={{ padding: "0.5rem", cursor: "pointer" }}
              >
                Proyecto de aprendizaje
              </a>
            )}        
          */}

          <a
            className="logout-mobile"
            style={{ padding: "0.5rem", cursor: "pointer" }}
            onClick={onLogout}
          >
            <LogOut width="50" height="50" /> Cerrar sesión
          </a>
        </Popover>
      </div>
      <div className="mobile-toggle">
        <button onClick={onOpen}>
          <MenuRoundedIcon />
        </button>
      </div>
      <div className={`menu-overlay ${hidden}`} onClick={onClose}></div>
      <div className={`mobile-menu ${hidden}`}>
        <button onClick={onClose}>
          <CloseRoundedIcon />
        </button>
        <nav>
          <ul>
            {!isLoggedIn && (
              <>
                <li>
                  <a href="/login">Iniciar Sesión</a>
                </li>
                <li>
                  <a href="/signup">Registrarme</a>
                </li>
              </>
            )}
            
            {/* Escenario 1: El usuario tiene una compra activa */}
            {hasPurchase && isLoggedIn ? (
              <>
                <li>
                  <a href="/progreso">Vocacionamiento</a>
                </li>
                <li>
                  <a href="https://courses.skillingcenter.com" target="_blank">Formación</a>
                </li>
                <li>
                  <a href="https://chat.whatsapp.com/Ffdejw3Sx6iKboxL1NmlMp" target="_blank">Acompañamiento</a>
                </li>
                <li>
                  <a href="/gym-profesional">Gym Profesional</a>
                </li>
                {purchaseStatus === "processing" && (
                  <li>
                    <a href="/checkout">¡Comprar experiencia!</a>
                  </li>
                )}
              </>
            ) : <></>}

            {/* Escenario 2: Comunmente dada en oxxo pay */}
            {!hasPurchase && lastPurchase?.status === "processing" && (
              <li>
                <a href="/checkout/return">¡Comprar experiencia!</a>
              </li>
            )}

            {/* Escenario 3: El usuario no tiene compra activa y el estado de compra es "null" */}
            {!hasPurchase && lastPurchase?.status === null && (
              <li>
                <a href="/checkout">¡Comprar experiencia!</a>
              </li>
            )}

            {/* Escenario 4: Usuario entra a checkout y se sale (o rechaza el pedido) */}
            {purchaseStatus === "started" && (
              <li>
                <a href="/checkout">¡Comprar experiencia!</a>
              </li>
            )}

            {/* Escenario 5: El usuario está registrado pero no tiene compra */}
            {isLoggedIn && !hasPurchase && lastPurchase?.status === null && (
              <li>
                <a href="/checkout">¡Comprar experiencia!</a>
              </li>
            )}

            {/* Escenario 6: Nuevos usuario*/}
            {isLoggedIn &&
              !hasPurchase &&
              (lastPurchase?.status === null || lastPurchase?.status === undefined) && (
                <li>
                  <a href="/checkout">¡Comprar experiencia!</a>
                </li>
              )}
          </ul>
        </nav>
        <div className="mobile-profile">
          <a
            href="/perfil"
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            {isLoggedIn && (
              <Avatar
                sx={{
                  bgcolor: "var(--secondary-dark)",
                  color: "var(--primary)",
                }}
                alt={isLoggedIn ? authUser?.name : "-"}
              >
                {authUser?.name && authUser.name[0]}
              </Avatar>
            )}

            <p style={{ marginLeft: "15px", color: "inherit" }}>{authUser?.name}</p>
          </a>
        </div>
        {isLoggedIn && (
          <a className="logout-mobile" onClick={onLogout}>
            <LogOut width="50" height="50" /> Cerrar sesión
          </a>
        )}
      </div>
    </div>
  );
}
