import React, { useState, useRef, useEffect } from "react";
import styles from "./Sidebar.module.css";
import DeleteIcon from '@mui/icons-material/Delete';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Toast from "../Toast/Toast";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import axios from "axios"


const Sidebar = ({ objectives, onAddObjective, onDeleteObjective, selectedObjective, setSelectedObjective, updateObjectives }) => {
  const url = process.env.REACT_APP_API_URL || "http://localhost:3501";

  console.log("Objectivos", objectives)

  const [newGoal, setNewGoal] = useState({
    category: "",
    title: "",
    date: "",
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [visibleSections, setVisibleSections] = useState({});

  const buttonRef = useRef(null);

  const toggleSection = (id) => {
    setVisibleSections((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const handleClickGoal = (goal, event) => {
    if (event.target.closest("button")) return;

    setSelectedObjective(goal);
    toggleSection(goal.id_objective);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") return;
    setSnackbarOpen(false);
  };

  const completeObjective = async (id) => {
    try {
      const response = await axios.post(`${url}/objectives/complete-objective/${id}`);
      if (response.status === 200) {
        setToastMessage("Objetivo marcado como completado con éxito.");
        setSnackbarOpen(true);

        // Actualiza el estado local
        const updatedObjectives = objectives.map((goal) =>
          goal.id_objective === id ? { ...goal, is_completed: true } : goal
        );
        updateObjectives(updatedObjectives); // Notifica al componente padre
      } else {
        throw new Error("No se pudo completar el objetivo.");
      }
    } catch (error) {
      console.error("Error al completar el objetivo:", error);
      setToastMessage("Error al completar el objetivo.");
      setSnackbarOpen(true);
    }
  };

  useEffect(() => {
    const initialState = objectives.reduce((acc, goal) => {
      acc[goal.id_objective] = goal.is_completed || false;
      return acc;
    }, {});
  }, [objectives]);

  const [showAddGoal, setShowAddGoal] = useState(false);

  const getTagColor = (category) => {
    switch (category) {
      case "Experiencia laboral":
        return "#FFA85D"; // Naranja
      case "Networking":
        return "#68C7BF"; // Turquesa
      case "Formación":
        return "#D5F694"; // Verde claro
      case "Otro":
        return "#B0B0B0"; // Gris claro
      default:
        return "#FF6B6B"; // Rojo para valores no identificados
    }
  };

  const handleAddGoal = () => {
    if (newGoal.category && newGoal.title && newGoal.date) {
      onAddObjective(newGoal);
      setNewGoal({ category: "", title: "", date: "" });
    } else {
      setToastMessage("Por favor, completa todos los campos.");
      setSnackbarOpen(true);
    }
  };

  const addGoalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        addGoalRef.current &&
        !addGoalRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        closeAddGoal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeAddGoal = () => {
    setShowAddGoal(false);
    setNewGoal({ category: "", title: "", date: "" });
  };

  return (
    <div className={styles.sidebarContainer}>
      <button
        ref={buttonRef}
        className={styles.addGoalToggle}
        onClick={(event) => {
          event.stopPropagation();
          if (showAddGoal) {
            closeAddGoal();
          } else {
            setShowAddGoal(true);
          }
        }}
      >
        {showAddGoal ? "- Cerrar" : "+ Agregar objetivo"}
      </button>

      {showAddGoal && (
        <div className={styles.addGoal} ref={addGoalRef}>
          <select
            value={newGoal.category}
            onChange={(e) => setNewGoal({ ...newGoal, category: e.target.value })}
            className={styles.input}
          >
            <option value="">Seleccionar categoría</option>
            <option value="Formación">Formación</option>
            <option value="Networking">Networking</option>
            <option value="Experiencia laboral">Experiencia laboral</option>
            <option value="Otro">Otro</option>
          </select>
          <input
            type="text"
            placeholder="Escribe aquí..."
            value={newGoal.title}
            onChange={(e) => setNewGoal({ ...newGoal, title: e.target.value })}
            className={styles.input}
          />
          <input
            type="date"
            value={newGoal.date}
            onChange={(e) => setNewGoal({ ...newGoal, date: e.target.value })}
            className={styles.input}
          />
          <button onClick={handleAddGoal} className={styles.addButton}>
            Agregar objetivo
          </button>
        </div>
      )}
      <div className={styles.goalsList}>
        {objectives.slice().reverse().map((goal, index) => (
          <div
            key={goal.id_objective}
            className={`${styles.goalItem} ${selectedObjective?.id_objective === goal.id_objective ? styles.selectedGoal : ""
              }`}
            onClick={(event) => handleClickGoal(goal, event)}
          >
            <div className={styles.category}>
              <span
                className={styles.goalCategory}
                style={{ backgroundColor: getTagColor(goal.category) }}
              >
                {goal.category}
              </span>
            </div>
            <h4>{goal.title}</h4>
            <div className={styles.goalActions}>
              <p className={styles.dateIcon}>
                <CalendarMonthIcon fontSize="small" /> {goal.due_date}
              </p>
              {visibleSections[goal.id_objective] && (
                <div className={styles.dateAndDelete}>
                  <button
                    className={styles.completeButton}
                    onClick={() => completeObjective(goal.id_objective)}
                    disabled={goal.is_completed}
                  >
                    {goal.is_completed ? (
                      <CheckCircleIcon fontSize="small" color="success" />
                    ) : (
                      <RadioButtonUncheckedIcon fontSize="small" />
                    )}
                    {goal.is_completed ? "Completado" : "Completar objetivo"}
                  </button>
                  <button
                    className={styles.deleteButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteObjective(goal.id_objective);
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </button>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      <Toast open={snackbarOpen} onClose={handleCloseSnackbar} message={toastMessage} />
    </div>
  );
};

export default Sidebar;
