/**
 * Checks if the given content contains code blocks.
 *
 * @param {string} content - The content to check.
 * @returns {boolean} - True if the content contains code blocks, false otherwise.
 */
export const containsCode = (content) => {
  const codePattern = /```[\s\S]*?```/;
  return codePattern.test(content);
};

/**
 * Checks if the given content contains a table.
 *
 * @param {string} content - The content to check.
 * @returns {boolean} - True if the content contains a table, false otherwise.
 */
export const containsTable = (content) => {
  const tablePattern = /\|.*?\|/;
  return tablePattern.test(content);
};
