import React, { useEffect } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { format, parse, startOfWeek, getDay } from "date-fns";
import es from "date-fns/locale/es";
import styles from "./CalendarWorkPlan.module.css";
import CalendarToolBar from "./CalendarToolBar";

const locales = { es };

const localizer = dateFnsLocalizer({
  format: (date, formatStr, options) => format(date, formatStr, { ...options, locale: es }),
  parse: (dateString, formatStr, options) => parse(dateString, formatStr, { ...options, locale: es }),
  startOfWeek: () => 1,
  getDay: (date) => getDay(date),
  locales,
});

const parseLocalDate = (dateString) => {
  const [year, month, day] = dateString.split("-");
  return new Date(year, month - 1, day);
};

const CalendarWorkPlan = ({ objectives, selectedObjective, setSelectedObjective }) => {
  const events = objectives.map((obj) => ({
    id_objective: obj.id_objective,
    title: obj.title,
    start: parseLocalDate(obj.due_date),
    end: parseLocalDate(obj.due_date),
    category: obj.category,
    is_completed: obj.is_completed
  }));

  // Definir colores por categoría
  const getCategoryColor = (category) => {
    switch (category) {
      case "Experiencia laboral":
        return "#FFA85D";
      case "Networking":
        return "#68C7BF";
      case "Formación":
        return "#D5F694";
      case "Otro":
        return "#CCCCCC";
      default:
        return "#FF6B6B"; 
    }
  };

  const eventStyleGetter = (event) => {
    console.log(selectedObjective)
    const isSelected = selectedObjective && selectedObjective.id_objective === event.id_objective;

    const backgroundColor = event.is_completed ? "#A9A9A9" : getCategoryColor(event.category);
    const borderColor = isSelected ? "#004DFF" : "transparent";

    return {
      style: {
        backgroundColor,
        borderRadius: "4px",
        color: event.is_completed ? "#909090" : "black",        
        border: `2px solid ${borderColor}`,
        padding: "5px",
        fontSize: "14px",
        opacity: event.is_completed ? 0.9 : 1,
      },
    };
  };

  useEffect(() => {
    console.log("Eventos del calendario:", events);
  }, [events]);

  return (
    <div className={styles.calendarContainer}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        className={styles.calendar}
        components={{
          toolbar: CalendarToolBar,
        }}
        onSelectEvent={(event) => {
          const matchedObjective = objectives.find((obj) => obj.id_objective === event.id_objective);
          setSelectedObjective(matchedObjective);
        }}        
        style={{
          height: window.innerHeight - 200,
          width: "100%",
        }}
        eventPropGetter={eventStyleGetter}
        messages={{
          next: "Siguiente",
          previous: "Anterior",
          today: "Hoy",
          month: "Mes",
          week: "Semana",
          day: "Día",
          agenda: "Agenda",
          date: "Fecha",
          time: "Hora",
          event: "Evento",
          noEventsInRange: "No hay eventos en este rango.",
        }}
      />
    </div>
  );
};

export default CalendarWorkPlan;
