import styles from "./Vocacionamiento.module.css";
import { Breadcrumbs, Button, Form, Popup, Sidebar } from "../../components";
import { useEffect, useState, useRef } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import Cookies from "js-cookie";
import PiBot from "../../components/PiBot/PiBot";

const IMAGE_URL = `https://${process.env.REACT_APP_AZURE_BLOB_ACCOUNT_NAME}.blob.core.windows.net/questions`;

export default function Vocacionamiento() {
  const navigate = useNavigate();
  const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
  const [sidebar, setSidebar] = useState([]);
  const [moduleTitle, setModuleTitle] = useState("");
  const [content, setContent] = useState([]);
  const [hidePrev, setHidePrev] = useState(false);
  const [hideNext, setHideNext] = useState(false);
  const moduleToDisplay = useRef("");
  const stepToDisplay = useRef("");
  const formAnswers = useRef([]);
  const initialAnswers = useRef([]);
  const hasSurvey = useRef(false);
  const [validateNext, setValidateNext] = useState(false);
  const surveyLength = useRef(0);
  const [blockId, setBlockId] = useState("");
  const [blockTitle, setBlockTitle] = useState("");
  const [loader, setLoader] = useState(true);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  console.log("-------------------------------------");

  console.log("Form answers principal: ", formAnswers.current);
  console.log("Block: ", blockId);
  console.log("ID:", authUser?.current_step);

  // Get query params
  const queryParams = new URLSearchParams(window.location.search);

  const [currentStepNumber, setCurrentStepNumber] = useState(null);
  const [currentModuleNumber, setCurrentModuleNumber] = useState(null);

  const url = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "http://localhost:3501";

  const reloadProgress = () => {
    axios
      .get(`${url}/modules/get-steps/${moduleToDisplay.current}`)
      .then((response) => {
        const steps = response.data.data.steps.map((step, index) => ({
          id: step.id_step,
          title: step.title,
          status:
            index <= sidebar.findIndex((s) => s.id === stepToDisplay.current)
              ? "complete"
              : "pending",
        }));
        setSidebar(steps);
        setLoader(false);
      })
      .catch((error) => console.error("Error recargando el progreso:", error));
  };

  //Opcional de current step para evitar error
  useEffect(() => {
    if (!authUser?.current_module && sidebar.length > 0) {
      // Asigna el primer módulo y paso si no están definidos
      axios
        .get(`${url}/modules/get-modules/${authUser.id}`)
        .then((response) => {
          const modules = response.data.data.modules;
          if (modules.length > 0) {
            // Configura el primer módulo
            const firstModule = modules[0].id_module;

            const currentIndex = sidebar.findIndex(
              (element) => element.id == authUser.current_step
            );

            axios
              .get(`${url}/modules/get-steps/${firstModule}`)
              .then((stepResponse) => {
                const steps = stepResponse.data.data.steps;
                if (steps.length > 0) {
                  const firstStep = steps[0].id_step;

                  var current_step_new = "";

                  if (currentIndex === -1) {
                    current_step_new = authUser?.current_step;
                  } else {
                    current_step_new = firstStep;
                  }

                  // Actualiza el estado y el backend
                  const updatedUser = {
                    ...authUser,
                    current_module: firstModule,
                    current_step: current_step_new,
                  };

                  setAuthUser(updatedUser);
                  axios
                    .put(`${url}/user/update-user/${authUser.id}`, {
                      current_module: firstModule,
                      current_step: current_step_new,
                    })
                    .then(() => {
                      moduleToDisplay.current = firstModule;
                      stepToDisplay.current = firstStep;
                      reloadProgress();
                    })
                    .catch((error) => {
                      console.error(
                        "Error al actualizar el usuario al primer módulo:",
                        error
                      );
                    });
                }
              })
              .catch((error) => {
                console.error(
                  "Error al obtener los pasos del primer módulo:",
                  error
                );
              });
          }
        })
        .catch((error) => {
          console.error("Error al obtener los módulos:", error);
        });
    }
  }, [authUser, sidebar.length]);

  const displayContent = () => {
    let final = false;
    let hasAns = false;

    // Obtener los pasos del módulo actual
    axios
      .get(`${url}/modules/get-steps/${moduleToDisplay.current}`)
      .then((response) => {
        const allSteps = response.data.data.steps || [];

        if (allSteps.length === 0) {
          console.warn("No se encontraron pasos para el módulo actual.");
          setContent([
            <p key="no-steps">No hay pasos disponibles para este módulo.</p>,
          ]);
          return;
        }


        // Normalizar IDs para evitar inconsistencias
        const normalizedSteps = allSteps.map((step) => ({
          ...step,
          id_step: String(step.id_step),
        }));

        const normalizedCurrentStep = String(stepToDisplay.current);
        const normalizedUserStep = String(authUser.current_step);

        // Identificar índices del paso actual y del paso del usuario
        const currentStepIndex = normalizedSteps.findIndex(
          (step) => step.id_step === normalizedCurrentStep
        );
        const userStepIndex = normalizedSteps.findIndex(
          (step) => step.id_step === normalizedUserStep
        );
        console.log("Block id; ", allSteps[currentStepIndex]);
        setBlockId(allSteps[currentStepIndex].id);

        setBlockTitle(allSteps[currentStepIndex].title);
        // Obtener los módulos
        axios
          .get(`${url}/modules/get-modules/${authUser.id}`)
          .then((response) => {
            const modules = response.data.data.modules;

            // Identificar el módulo actual
            const currentIndex = modules.findIndex(
              (module) => module.id_module === moduleToDisplay.current
            );

            // Establecer el título del módulo actual
            setModuleTitle(modules[currentIndex].module_title);

            if (currentIndex !== -1) {
              // Verificar si el paso del usuario está dentro del módulo actual
              const userInCurrentModule = normalizedSteps.some(
                (step) => step.id_step === normalizedUserStep
              );

              // Configuración de navegación
              if (currentIndex === 0 && currentStepIndex === 0) {
                setHidePrev(true);
                setHideNext(false);
              } else if (
                currentIndex === modules.length - 1 &&
                currentStepIndex === normalizedSteps.length - 1
              ) {
                setHidePrev(false);
                setHideNext(true);
                final = true;
              } else {
                setHidePrev(false);
                setHideNext(false);
              }

              // Validar y marcar los pasos
              let steps = [];
              if (userInCurrentModule) {
                // El paso del usuario está dentro del módulo actual
                steps = normalizedSteps.map((step, index) => ({
                  id: step.id_step,
                  title: step.title,
                  status: index <= userStepIndex ? "complete" : "pending",
                }));
              } else if (
                userStepIndex === -1 ||
                userStepIndex > currentStepIndex
              ) {
                // El paso del usuario está más adelante que todos los pasos del módulo actual
                steps = normalizedSteps.map((step) => ({
                  id: step.id_step,
                  title: step.title,
                  status: "complete",
                }));
              } else if (userStepIndex < currentStepIndex) {
                // El paso del usuario está antes del módulo actual
                steps = normalizedSteps.map((step) => ({
                  id: step.id_step,
                  title: step.title,
                  status: "pending",
                }));
              } else {
                // Fallback en caso de error o pasos no relacionados
                console.warn(
                  "El paso del usuario no pertenece a este módulo ni a un módulo válido."
                );
                steps = normalizedSteps.map((step) => ({
                  id: step.id_step,
                  title: step.title,
                  status: "pending",
                }));
              }
              // Actualizar el sidebar con los pasos
              setSidebar(steps);
            } else {
              console.warn("El módulo actual no fue encontrado en la lista.");
            }
          })
          .catch((error) => {
            console.error("Error al obtener los módulos:", error);
          });

        // Obtener los bloques del paso actual
        axios
          .get(`${url}/modules/get-blocks/${stepToDisplay.current}`)
          .then(async (response) => {
            const blocks = [];
            const blockData = response.data.data.blocks;

            for (let i = 0; i < blockData.length; i++) {
              const block = blockData[i];

              console.log("Blocks:", block);

              const modifyLinks = (htmlContent) => {
                const div = document.createElement("div");
                div.innerHTML = htmlContent;

                // Seleccionar todos los <p> y sus <a>
                div.querySelectorAll("p a").forEach((link) => {
                  link.setAttribute("target", "_blank");
                  link.setAttribute("rel", "noopener noreferrer");
                });

                return div.innerHTML;
              };

              // Modificar los enlaces en block_description
              const processedDescription = modifyLinks(block.block_description);

              // Agregar descripción del bloque
              if (block.block_description) {
                blocks.push(
                  <div
                    key={`desc-${block.id_block}`}
                    dangerouslySetInnerHTML={{
                      __html: processedDescription,
                    }}
                  />
                );
              }

              // Agregar imágenes del bloque
              if (block.image?.length > 0) {
                blocks.push(
                  <img
                    key={`image-${block.id_block}`}
                    src={`${IMAGE_URL}/${block.image}`}
                    alt={block.title}
                  />
                );
              }

              // Procesar encuestas del bloque
              if (block.survey && block.survey.hasOwnProperty("survey")) {
                const newSurvey = block.survey.survey.filter(
                  (el) => el != null
                );

                surveyLength.current += newSurvey.length;

                if (surveyLength.current > 0) {
                  hasSurvey.current = true;

                  await axios
                    .get(
                      `${url}/answers/get-answers/${authUser.id}/${block.id_block}`
                    )
                    .then((responseAns) => {
                      const answers = responseAns.data.data.answers;

                      if (answers.length > 0) {
                        hasAns = true;

                        formAnswers.current = [...answers];

                        answers.forEach((answer) => {
                          const ansIndex = newSurvey.findIndex(
                            (q) => q.id_question === answer.id_question
                          );
                          if (ansIndex !== -1) {
                            newSurvey[ansIndex].answer = answer.answer;
                          }
                        });

                        blocks.push(
                          <Form
                            key={`form-${block.id_block}`}
                            survey={newSurvey}
                            block={block.id_block}
                            user={authUser.id}
                            callback={formCallback}
                          />
                        );
                      } else {
                        blocks.push(
                          <Form
                            key={`form-empty-${block.id_block}`}
                            survey={newSurvey}
                            block={block.id_block}
                            user={authUser.id}
                            callback={formCallback}
                          />
                        );
                      }
                    })
                    .catch((error) => {
                      console.error("Error al obtener las respuestas:", error);
                    });
                }
              }
            }

            console.log("blocks: ", blocks);
            setBlockId(blocks.key);

            setContent(blocks);

            // Configuración final de navegación
            if (final && hasSurvey.current) {
              setValidateNext(
                formAnswers.current.length < surveyLength.current
              );
              setHideNext(true);
            } else {
              setValidateNext(hasSurvey.current);
              setHideNext(false);
            }
            setLoader(false);
          })
          .catch((error) => {
            console.error("Error al obtener los bloques:", error);
            setContent([
              <p key="no-content">No se encontró contenido para este paso.</p>,
            ]);
          });
      })
      .catch((error) => {
        console.error("Error al obtener los pasos:", error);
      });
  };

  const quickRender = (isPast) => {
    // Use this function when the sidebar is already set
    // Get Blocks
    let final = false;
    let hasAns = false;
    axios
      .get(url + `/modules/get-blocks/${stepToDisplay.current}`)
      .then(async (response) => {
        const blocks = [];
        for (let i = 0; i < response.data.data.blocks.length; i++) {
          if (response.data.data.blocks[i].block_description != null) {
            const modifyLinks = (htmlContent) => {
              const div = document.createElement("div");
              div.innerHTML = htmlContent;

              div.querySelectorAll("p a").forEach((link) => {
                link.setAttribute("target", "_blank");
                link.setAttribute("rel", "noopener noreferrer");
              });

              return div.innerHTML;
            };

            const processedDescription = modifyLinks(
              response.data.data.blocks[i].block_description
            );

            blocks.push(
              <div
                dangerouslySetInnerHTML={{
                  __html: processedDescription,
                }}
              />
            );
          }
          if (response.data.data.blocks[i]?.image?.length > 0) {
            blocks.push(
              <img src={`${IMAGE_URL}/${response.data.data.blocks[i].image}`} />
            );
          }
          if (
            response.data.data.blocks[i].survey != null &&
            response.data.data.blocks[i].survey.hasOwnProperty("survey")
          ) {
            const newSurvey = response.data.data.blocks[i].survey.survey.filter(
              (el) => el != null
            );
            surveyLength.current += newSurvey.length;
            // Check if answers already exist
            await axios
              .get(
                `${url}/answers/get-answers/${authUser.id}/${response.data.data.blocks[i].id_block}`
              )
              .then((responseAns) => {
                if (responseAns.data.data.answers.length > 0) {
                  hasAns = true;
                  // Build survey object with answers
                  const answers = responseAns.data.data.answers;
                  initialAnswers.current = [
                    ...initialAnswers.current,
                    ...answers,
                  ];
                  for (let j = 0; j < answers.length; j++) {
                    // Find index of current answer

                    const ansIndex = newSurvey.findIndex(
                      (element) => element.id_question == answers[j].id_question
                    );

                    newSurvey[ansIndex].answer = answers[j].answer;
                  }
                  blocks.push(
                    <Form
                      survey={newSurvey}
                      block={response.data.data.blocks[i].id_block}
                      user={authUser.id}
                      callback={formCallback}
                    />
                  );
                } else {
                  hasAns = false;
                  hasSurvey.current = true;
                  blocks.push(
                    <Form
                      survey={newSurvey}
                      block={response.data.data.blocks[i].id_block}
                      user={authUser.id}
                      callback={formCallback}
                    />
                  );
                }
              })
              .catch((error) => console.error(error));
          }
        }
        setContent(Array.from(blocks));
        setLoader(false);
      })
      .catch((error) => {
        console.error(error);
        setContent(
          Array.from([<p>No se encontró contenido para este paso.</p>])
        );
      });
  };

  const formCallback = (answers) => {
    answers.forEach((newAnswer) => {
      const index = formAnswers.current.findIndex(
        (element) => element.id_question === newAnswer.id_question
      );
      if (index > -1) {
        formAnswers.current[index] = newAnswer;
      } else {
        formAnswers.current.push(newAnswer);
      }
    });
    validateForm();
  };

  const validateForm = () => {
    const isIncomplete = formAnswers.current.some((answer) => {
      if (!answer || !answer.answer) {
        return true;
      }
      if (typeof answer.answer === "string") {
        const isEmpty = !answer.answer.trim();
        if (isEmpty) return isEmpty;
      } else if (Array.isArray(answer.answer)) {
        const isEmptyArray = answer.answer.length === 0;
        if (isEmptyArray) return isEmptyArray;
      } else if (typeof answer.answer === "object" && answer.answer !== null) {
        const hasEmptyValues = Object.values(answer.answer).some(
          (value) => value === null || value === undefined || value === ""
        );
        if (hasEmptyValues) return hasEmptyValues;
      }
      return false;
    });

    setValidateNext(isIncomplete);
  };

  useEffect(() => {
    // Obtener todas las preguntas del contenido actual
    const questionsInSurvey = content
      .filter((block) => block.props && block.props.survey)
      .flatMap((block) => block.props.survey)
      .map((question) => question.id_question);

    // Sincronizar las respuestas actuales con las preguntas del paso actual
    const syncedAnswers = questionsInSurvey.map((id) => {
      // Buscar la respuesta existente en formAnswers.current
      const existingAnswer = formAnswers.current.find(
        (response) => response.id_question === id
      );

      if (existingAnswer) {
        // Si existe una respuesta, usarla
        return existingAnswer;
      }

      // Si no hay respuesta existente, buscar la pregunta en el contenido actual
      const question = content
        .filter((block) => block.props && block.props.survey)
        .flatMap((block) => block.props.survey)
        .find((q) => q.id_question === id);

      // Retornar un objeto con la pregunta y su respuesta inicial o null
      return {
        id_question: id,
        answer: question?.answer || null,
      };
    });

    // Actualizar formAnswers.current solo con respuestas del paso actual
    formAnswers.current = syncedAnswers;

    // Validar después de sincronizar
    validateForm();
  }, [content]);

  useEffect(() => {
    setLoader(true);

    hasSurvey.current = false;
    surveyLength.current = 0;
    formAnswers.current = [];

    const storedUser = Cookies.get("authUser");
    if (!isLoggedIn && storedUser) {
      const user = JSON.parse(storedUser);
      if (user && user.token) {
        setAuthUser(user);
        setIsLoggedIn(true);
        onFirstLoad();
      } else {
        setAuthUser(null);
        setIsLoggedIn(false);
        navigate("/login");
      }
    } else if (!isLoggedIn) {
      navigate("/login");
    } else {
      onFirstLoad();
    }
  }, [authUser, isLoggedIn]);

  useEffect(() => {
    // Actualizar el número del paso actual desde el `sidebar`
    if (sidebar.length > 0 && stepToDisplay.current) {
      const currentStepIndex = sidebar.findIndex(
        (step) => step.id === stepToDisplay.current
      );
      if (currentStepIndex !== -1) {
        setCurrentStepNumber(currentStepIndex + 1); // Guarda el índice +1 como el número del paso
      } else {
        console.warn("Paso actual no encontrado en el sidebar");
      }
    }

    // Actualizar el número del módulo actual
    if (moduleToDisplay.current) {
      axios
        .get(url + "/modules/get-modules/" + authUser.id)
        .then((response) => {
          const modules = response.data.data.modules;
          const moduleIndex = modules.findIndex(
            (module) => module.id_module === moduleToDisplay.current
          );

          if (moduleIndex !== -1) {
            setCurrentModuleNumber(moduleIndex + 1); // Guarda el índice +1 como el número del módulo
          } else {
            console.warn("Módulo actual no encontrado en la lista de módulos");
          }
        })
        .catch((error) => console.error(error));
    }
  }, [sidebar, stepToDisplay.current, moduleToDisplay.current]);

  const onFirstLoad = () => {
    if (authUser?.current_module != null && isFirstLoad) {
      setIsFirstLoad(false);

      const queryModule = queryParams.get("module");

      if (queryModule) {
        moduleToDisplay.current = queryModule;

        axios
          .get(`${url}/modules/get-steps/${queryModule}`)
          .then((response) => {
            const steps = response.data.data.steps;

            const firstStep = steps[0].id_step;
            stepToDisplay.current = firstStep;

            displayContent();
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        moduleToDisplay.current = authUser.current_module;
        stepToDisplay.current = authUser.current_step;
        const currentStep = sidebar.find(
          (step) => step.id === stepToDisplay.current
        );
        if (currentStep && !queryModule) {
          setCurrentStepNumber(currentStep.number);
        }

        displayContent();
      }
    } else if (authUser && isFirstLoad) {
      setIsFirstLoad(false);
      axios
        .get(`${url}/modules/get-modules/${authUser.id}`)
        .then((response) => {
          let currentModule = response.data.data.modules[0].id_module;

          if (queryParams.get("module")) {
            currentModule = queryParams.get("module");
          }
          axios
            .get(`${url}/modules/get-steps/${currentModule}`)
            .then((response) => {
              const currentStep = response.data.data.steps[0].id_step;

              if (queryParams.get("module")) {
                moduleToDisplay.current = currentModule;
                stepToDisplay.current = currentStep;
              } else {
                authUser.current_module = currentModule;
                authUser.current_step = currentStep;
                Cookies.set("authUser", JSON.stringify(authUser), {
                  expires: 7,
                });

                moduleToDisplay.current = authUser?.current_module;
                stepToDisplay.current = authUser?.current_step;
              }
              displayContent();
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const addAnswer = async () => {
    try {
      await axios.post(url + "/answers/add-answer", {
        answers: formAnswers.current,
        source: "life-design",
        idStep: stepToDisplay.current,
        idBlock: blockId,
      });
      console.log("Respuesta agregada correctamente");
    } catch (error) {
      console.error("Error al agregar la respuesta:", error);
    }
  };

  const validateAnswers = () => {
    let hasSurveyBeenModified = false;

    const isAnsweredCompletely = formAnswers.current.every((answer) => {
      if (!answer || !answer.answer) {
        return false; // Si hay alguna respuesta incompleta, no está completamente respondido
      }
      return true;
    });

    // Detectar si las respuestas actuales han cambiado
    for (let i = 0; i < formAnswers.current.length; i++) {
      const currentAnswer = formAnswers.current[i];
      const isModified =
        typeof currentAnswer.answer === "string"
          ? currentAnswer.answer.trim() !== ""
          : Array.isArray(currentAnswer.answer)
          ? currentAnswer.answer.length > 0
          : typeof currentAnswer.answer === "object"
          ? Object.values(currentAnswer.answer).some(
              (value) => value !== null && value !== ""
            )
          : false;

      if (isModified) {
        hasSurveyBeenModified = true;
        break;
      }
    }

    console.log("Is Completely Answered:", isAnsweredCompletely);
    console.log("Has Survey Been Modified:", hasSurveyBeenModified);

    return isAnsweredCompletely && hasSurveyBeenModified;
  };

  const onNext = () => {
    setLoader(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    var hasBeenAnswered = validateAnswers();

    if (hasBeenAnswered) {
      axios.post(url + "/answers/add-answer", {
        answers: formAnswers.current,
        source: "life-design",
      });
      //addAnswer();
    }

    hasSurvey.current = false;
    surveyLength.current = 0;
    formAnswers.current = [];

    console.log("stepToDisplay", stepToDisplay);

    // Reset
    // Check if current_step needs to be updated
    // Get index of current_step
    const currentIndex = sidebar.findIndex(
      (element) => element.id == authUser.current_step
    );
    // Get index of displayed step
    const currentStep = sidebar.findIndex(
      (element) => element.id == stepToDisplay.current
    );


    const nextStep = currentStep + 1;
    const hasNextStep = currentStep + 1 < sidebar.length;

    if (
      hasNextStep &&
      ((hasSurvey.current &&
        formAnswers.current.length === surveyLength.current) ||
        hasBeenAnswered ||
        !hasSurvey.current)
    ) {
      stepToDisplay.current = sidebar[nextStep].id;

      setBlockTitle(sidebar[nextStep].title);
      const updatedSidebar = sidebar.map((step, index) => ({
        ...step,
        status: index <= nextStep ? "complete" : step.status,
      }));

      setSidebar(updatedSidebar);

      if (authUser.current_step && stepToDisplay.current) {
        // Si el paso actual del usuario no está en el módulo actual
        const userStepIndex = sidebar.findIndex(
          (step) => step.id === authUser.current_step
        );

        const stepToDisplayIndex = sidebar.findIndex(
          (step) => step.id === stepToDisplay.current
        );

        const nextStepId = sidebar[nextStep]?.id;

        if (currentIndex === -1) {
          // El paso actual del usuario pertenece a otro módulo

          if (
            authUser.current_step === nextStepId ||
            currentIndex < stepToDisplayIndex
          ) {
            quickRender(); // Renderizar contenido actualizado
          }
        } else if (currentIndex >= nextStep) {
          axios
            .get(`${url}/modules/get-modules/${authUser.id}`)
            .then((modulesResponse) => {
              const modules = modulesResponse.data.data.modules;
              const currentStepIndex = sidebar.findIndex(
                (element) => element.id == stepToDisplay.current
              );

              const currentModuleIndex = modules.findIndex(
                (module) => module.id_module === moduleToDisplay.current
              );

              axios
                .get(`${url}/modules/get-steps/${moduleToDisplay.current}`)
                .then((stepsResponse) => {
                  const steps = stepsResponse.data.data.steps;

                  const currentStepIndex = sidebar.findIndex(
                    (element) => element.id == stepToDisplay.current
                  );

                  const isLastModule =
                    currentModuleIndex === modules.length - 1;
                  const isLastStepInModule =
                    !steps[currentStepIndex + 1] ||
                    steps[currentStepIndex + 1].id_step == null;
                  const isFinalStepProgram = isLastModule && isLastStepInModule;
                  if (isFinalStepProgram) {
                    setHidePrev(false);
                    setHideNext(true);
                    setValidateNext(false);
                  }
                  quickRender(); // Renderizar contenido actualizado
                  return; // Salir de la función sin actualizar el backend
                });
            });
        }
      }

      // Si el paso es igual o está adelante, registrar el progreso
      var current_step_new = "";

      /* se comento esto por el issue, pendiente revisar si es valido */
      if (currentIndex === -1) {
      current_step_new = authUser.current_step;
      } else {
      current_step_new = sidebar[nextStep].id;
       }
      axios
        .put(`${url}/user/update-user/${authUser.id}`, {
          current_module: authUser.current_module,
          current_step: current_step_new,
        })
        .then(() => {
          // Actualizar el usuario localmente

          const updatedUser = { ...authUser };
          updatedUser.current_step = current_step_new;
          setAuthUser(updatedUser);

          axios
            .get(url + "/modules/get-modules/" + authUser.id)
            .then((response) => {
              const modules = response.data.data.modules;

              // Encontrar índice del módulo actual
              const currentModuleIndex = modules.findIndex(
                (element) => element.id_module == moduleToDisplay.current
              );

              axios
                .get(`${url}/modules/get-steps/${moduleToDisplay.current}`)
                .then((stepsResponse) => {
                  const steps = stepsResponse.data.data.steps;

                  const currentStepIndex = sidebar.findIndex(
                    (element) => element.id == stepToDisplay.current
                  );

                  const isLastModule =
                    currentModuleIndex === modules.length - 1;
                  const isLastStepInModule =
                    !steps[currentStepIndex + 1] ||
                    steps[currentStepIndex + 1].id_step == null;
                  const isFinalStepProgram = isLastModule && isLastStepInModule;

                  // Configurar botones de navegación
                  if (currentModuleIndex === 0 && nextStep === 0) {
                    setHidePrev(true);
                    setHideNext(false);
                  } else if (isFinalStepProgram) {
                    setHidePrev(false);
                    setHideNext(true);
                  } else {
                    setHideNext(false);
                    setHidePrev(false);
                  }
                  if (isLastStepInModule) {
                    setValidateNext(false);
                  }
                  quickRender();
                });
            });
        })
        .catch((error) => {
          console.error("Error al actualizar el progreso:", error);
        });
    } else {
      // Compare with the step that's being displayed?
      hasSurvey.current = false;
      surveyLength.current = 0;
      formAnswers.current = [];
      if (currentStep > -1 && currentStep < currentIndex) {
        // The next step is in the same module and has been previously completed
        // No need to update user progress
        stepToDisplay.current = sidebar[currentStep + 1].id;
        setBlockTitle(sidebar[currentStep + 1].title);
        //Get modules to see if it's last index
        axios
          .get(url + "/modules/get-modules/" + authUser.id)
          .then((response) => {
            const modules = response.data.data.modules;
            // Find index of current module
            const currentModuleIndex = modules.findIndex(
              (element) => element.id_module == moduleToDisplay.current
            );

            axios
              .get(`${url}/modules/get-steps/${moduleToDisplay.current}`)
              .then((stepsResponse) => {
                const steps = stepsResponse.data.data.steps;

                const currentStepIndex = sidebar.findIndex(
                  (element) => element.id == stepToDisplay.current
                );

                const isLastModule = currentModuleIndex === modules.length - 1;
                const isLastStepInModule =
                  !steps[currentStepIndex + 1] ||
                  steps[currentStepIndex + 1].id_step == null;
                const isFinalStepProgram = isLastModule && isLastStepInModule;

                // Configurar botones de navegación
                if (currentModuleIndex === 0 && nextStep === 0) {
                  setHidePrev(true);
                  setHideNext(false);
                } else if (isFinalStepProgram) {
                  setHidePrev(false);
                  setHideNext(true);
                } else {
                  setHideNext(false);
                  setHidePrev(false);
                }
                if (isLastStepInModule) {
                  setValidateNext(false);
                }
                quickRender();
              });
          });
      } else if (currentIndex === -1) {
        // Current Step is in another module, no need to update progress
        // Get modules
        axios
          .get(url + "/modules/get-modules/" + authUser.id)
          .then((response) => {
            // Find index
            const modules = response.data.data.modules;
            const currentModuleIndex = modules.findIndex(
              (element) => element.id_module == moduleToDisplay.current
            );
            // Set module to display as next module
            moduleToDisplay.current = modules[currentModuleIndex + 1].id_module;
            setModuleTitle(modules[currentModuleIndex + 1].module_title);
            // Get Steps
            axios
              .get(url + `/modules/get-steps/${moduleToDisplay.current}`)
              .then((response) => {
                stepToDisplay.current = response.data.data.steps[0].id_step;
                // Get index of currently displayed step
                const currentStep = 0;
                const moduleIndex = modules.findIndex(
                  (element) => element.id_module === moduleToDisplay.current
                );
                setBlockTitle(response.data.data.steps[currentStep].title);
                axios
                  .get(`${url}/modules/get-steps/${moduleToDisplay.current}`)
                  .then((stepsResponse) => {
                    const steps = stepsResponse.data.data.steps;

                    const currentStepIndex = sidebar.findIndex(
                      (element) => element.id == stepToDisplay.current
                    );

                    const isLastModule =
                      currentModuleIndex === modules.length - 1;
                    const isLastStepInModule =
                      !steps[currentStepIndex + 1] ||
                      steps[currentStepIndex + 1].id_step == null;
                    const isFinalStepProgram =
                      isLastModule && isLastStepInModule;

                    // Configurar botones de navegación
                    // Check if prev or next button should be disabled
                    if (currentModuleIndex === 0 && currentStep === 0) {
                      // Verificar si existe un módulo previo
                      if (modules[currentModuleIndex]) {
                        // Hay un módulo anterior
                        setHidePrev(false);
                        setHideNext(false);
                      } else {
                        // No hay módulo previo, es el primer módulo y paso
                        setHidePrev(true);
                        setHideNext(false);
                      }
                    } else if (isFinalStepProgram) {
                      //It's the last step of the last module
                      setHidePrev(false);
                      setHideNext(true);
                    } else {
                      //It's somewhere in the middle
                      setHideNext(false);
                      setHidePrev(false);
                    }

                    if (isLastStepInModule) {
                      setValidateNext(false);
                    }
                  });
                // Set sidebar
                // Check if the new module that's being displayed matches user's progress
                if (authUser.current_module == moduleToDisplay.current) {
                  // Set sidebar contemplating user progress
                  // Get index of user's current_step
                  const currentStepIndex = response.data.data.steps.findIndex(
                    (element) => element.id_step == authUser.current_step
                  );
                  //Create array for sidebar
                  const steps = [];
                  for (let i = 0; i < response.data.data.steps.length; i++) {
                    i <= currentStepIndex
                      ? steps.push({
                          id: response.data.data.steps[i].id_step,
                          title: response.data.data.steps[i].title,
                          status: "complete",
                        })
                      : steps.push({
                          id: response.data.data.steps[i].id_step,
                          title: response.data.data.steps[i].title,
                          status: "pending",
                        });
                  }
                  setSidebar([...steps]);
                  quickRender();
                } else {
                  // Set sidebar where the user has already completed all steps
                  //Create array for sidebar
                  const steps = [];
                  for (let i = 0; i < response.data.data.steps.length; i++) {
                    steps.push({
                      id: response.data.data.steps[i].id_step,
                      title: response.data.data.steps[i].title,
                      status: "complete",
                    });
                  }
                  setSidebar([...steps]);
                  quickRender();
                }
              })
              .catch((error) => console.error(error));
          });
      } else if (currentIndex + 1 < sidebar.length) {
        // Progress needs to be updated, there's a next step in the current module
        axios
          .put(url + `/user/update-user/${authUser.id}`, {
            current_module: authUser.current_module,
            current_step: sidebar[currentIndex + 1].id,
          })
          .then((response) => {
            const newAuthUser = { ...authUser };
            newAuthUser.current_step = sidebar[currentIndex + 1].id;
            setAuthUser({ ...newAuthUser });
            // window.localStorage.setItem(
            //   "PIVOTEA_CURRENT_STEP",
            //   JSON.stringify(sidebar[currentIndex + 1].id)
            // );

            setAuthUser({ ...newAuthUser });

            const stepIndex = currentIndex + 1;
            stepToDisplay.current = sidebar[currentIndex + 1].id;
            setBlockTitle(sidebar[currentIndex + 1].title);
            //Update sidebar content
            const newSidebar = Array.from(sidebar);
            newSidebar[currentIndex + 1].status = "complete";
            setSidebar(Array.from(newSidebar));
            // Get modules to get current index
            axios
              .get(url + "/modules/get-modules/" + authUser.id)
              .then((response) => {
                const modules = response.data.data.modules;
                const currentModuleIndex = modules.findIndex(
                  (element) => element.id_module == authUser.current_module
                );
                const steps = response.data.data.steps;

                const currentStepIndex = sidebar.findIndex(
                  (element) => element.id == stepToDisplay.current
                );

                const isLastModule = currentModuleIndex === modules.length - 1;
                const isLastStepInModule =
                  !steps[currentStepIndex + 1] ||
                  steps[currentStepIndex + 1].id_step == null;
                const isFinalStepProgram = isLastModule && isLastStepInModule;

                // Check if prev or next button should be disabled
                if (currentModuleIndex === 0 && currentStep === 0) {
                  // Verificar si existe un módulo previo
                  if (modules[currentModuleIndex]) {
                    // Hay un módulo anterior
                    setHidePrev(false);
                    setHideNext(false);
                  } else {
                    // No hay módulo previo, es el primer módulo y paso
                    setHidePrev(true);
                    setHideNext(false);
                  }
                } else if (isFinalStepProgram) {
                  //It's the last step of the last module
                  setHidePrev(false);
                  setHideNext(true);
                } else {
                  //It's somewhere in the middle
                  setHideNext(false);
                  setHidePrev(false);
                }

                if (isLastStepInModule) {
                  setValidateNext(false);
                }
              })
              .catch((error) => console.error(error));
            quickRender();
          })
          .catch((error) => console.error(error));
      } else if (currentIndex === -1) {
        axios
          .get(`${url}/modules/get-modules/${authUser.id}`)
          .then((response) => {
            const modules = response.data.data.modules;
            const currentModuleIndex = modules.findIndex(
              (element) => element.id_module === moduleToDisplay.current
            );
            moduleToDisplay.current = modules[currentModuleIndex + 1].id_module;
            setModuleTitle(modules[currentModuleIndex + 1].module_title);

            axios
              .get(`${url}/modules/get-steps/${moduleToDisplay.current}`)
              .then((response) => {
                stepToDisplay.current = response.data.data.steps[0].id_step;
                const currentStep = 0;
                setBlockTitle(response.data.data.steps[currentStep].title);

                const stepsSide = response.data.data.steps;

                const currentStepIndex = sidebar.findIndex(
                  (element) => element.id == stepToDisplay.current
                );

                const isLastModule = currentModuleIndex === modules.length - 1;
                const isLastStepInModule =
                  !stepsSide[currentStepIndex + 1] ||
                  stepsSide[currentStepIndex + 1].id_step == null;
                const isFinalStepProgram = isLastModule && isLastStepInModule;
                if (currentModuleIndex === 0) {
                  setHidePrev(true);
                  setHideNext(false);
                } else if (isFinalStepProgram) {
                  setHidePrev(false);
                  setHideNext(true);
                } else {
                  setHidePrev(false);
                  setHideNext(false);
                }

                if (isLastStepInModule) {
                  setValidateNext(false);
                }

                const steps = response.data.data.steps.map((step, index) => ({
                  id: step.id_step,
                  title: step.title,
                  status: index === 0 ? "complete" : "pending",
                }));
                setSidebar([...steps]);
                quickRender();
              })
              .catch((error) =>
                console.error("Error al obtener los pasos:", error)
              );
          });
      } else if (currentIndex + 1 === sidebar.length) {
        // Progress needs to be updated, move on to the next module
        // 1. Get Modules
        axios
          .get(url + "/modules/get-modules/" + authUser.id)
          .then((response) => {
            // 2. Find index
            const modules = response.data.data.modules;
            const currentModuleIndex = modules.findIndex(
              (element) => element.id_module == authUser.current_module
            );
            // Check if it's the last module
            if (currentIndex + 1 < sidebar.length) {
              // Hay un siguiente paso en el módulo actual
              const nextStep = sidebar[currentIndex + 1];

              // Actualizar referencias al paso siguiente
              authUser.current_step = nextStep.id;
              stepToDisplay.current = nextStep.id;
              setBlockTitle(nextStep.title);

              // Actualizar usuario localmente
              const updatedUser = { ...authUser };
              updatedUser.current_step = nextStep.id;
              setAuthUser(updatedUser);
              // Actualizar el progreso del usuario en el backend
              axios
                .put(`${url}/user/update-user/${authUser.id}`, {
                  current_module: authUser.current_module,
                  current_step: nextStep.id,
                })
                .then(() => {
                  // Configurar botones de navegación
                  const hasNextStep = currentStep === sidebar.length - 1;
                  setHidePrev(false);
                  setHideNext(!hasNextStep);

                  quickRender();
                })
                .catch((error) => {
                  console.error("Error al actualizar el usuario:", error);
                });
            } else {
              // Es el último paso del módulo actual, avanzar al siguiente módulo
              axios
                .get(`${url}/modules/get-modules/${authUser.id}`)
                .then((response) => {
                  const modules = response.data.data.modules;
                  const currentModuleIndex = modules.findIndex(
                    (module) => module.id_module === moduleToDisplay.current
                  );

                  if (currentModuleIndex === -1) {
                    console.error("El módulo actual no se encontró.");
                    return;
                  }

                  if (currentModuleIndex + 1 < modules.length) {
                    // Hay un siguiente módulo
                    const nextModule = modules[currentModuleIndex + 1];

                    // Actualizar referencias al nuevo módulo
                    moduleToDisplay.current = nextModule.id_module; // Referencia principal
                    setModuleTitle(nextModule.module_title);

                    // Obtener los pasos del siguiente módulo
                    axios
                      .get(`${url}/modules/get-steps/${nextModule.id_module}`)
                      .then((stepResponse) => {
                        const steps = stepResponse.data.data.steps;

                        if (steps.length === 0) {
                          console.warn(
                            "No hay pasos disponibles en el siguiente módulo."
                          );
                          setContent(
                            <p
                              style={{ fontSize: "1.5em", textAlign: "center" }}
                            >
                              Este módulo no tiene pasos disponibles.
                            </p>
                          );
                          setHideNext(true);
                          return;
                        }

                        // Configurar el primer paso del siguiente módulo
                        const firstStep = steps[0];
                        stepToDisplay.current = firstStep.id_step;
                        setBlockTitle(firstStep.title);
                        // Actualizar la barra lateral con los nuevos pasos
                        const sidebarSteps = steps.map((step, index) => ({
                          id: step.id_step,
                          title: step.title,
                          status: index === 0 ? "complete" : "pending",
                        }));
                        setSidebar([...sidebarSteps]);

                        // Actualizar el usuario en el backend
                        axios
                          .put(`${url}/user/update-user/${authUser.id}`, {
                            current_module: nextModule.id_module,
                            current_step: firstStep.id_step,
                          })
                          .then(() => {
                            // Actualizar el usuario localmente solo al final
                            const updatedUser = { ...authUser };
                            updatedUser.current_module = nextModule.id_module;
                            updatedUser.current_step = firstStep.id_step;
                            setAuthUser(updatedUser);

                            // Configurar botones de navegación
                            setHidePrev(false);
                            setHideNext(
                              currentModuleIndex + 1 === modules.length - 1 &&
                                steps.length === 1
                            );

                            quickRender();
                          })
                          .catch((error) => {
                            console.error(
                              "Error al actualizar el usuario:",
                              error
                            );
                          });
                      })
                      .catch((error) => {
                        console.error(
                          "Error al obtener pasos del siguiente módulo:",
                          error
                        );
                      });
                  } else {
                    // No hay más módulos disponibles
                    setHideNext(true);
                    setContent(
                      <p style={{ fontSize: "1.5em", textAlign: "center" }}>
                        ¡Felicidades! Has completado todos los módulos. 🎉
                      </p>
                    );
                  }
                })
                .catch((error) => {
                  console.error("Error al obtener los módulos:", error);
                });
            }

            // 3. Get Steps for new module
            axios
              .get(url + `/modules/get-steps/${authUser.current_module}`)
              .then((response) => {
                // Set Step to first index
                authUser.current_step = response.data.data.steps[0].id_step;
                // window.localStorage.setItem(
                //   "PIVOTEA_CURRENT_STEP",
                //   JSON.stringify(response.data.data.steps[0].id_step)
                // );

                setAuthUser({ ...authUser });

                stepToDisplay.current = response.data.data.steps[0].id_step;
                setBlockTitle(response.data.data.steps[0].title);

                const stepsSide = response.data.data.steps;
                const currentStepIndex = sidebar.findIndex(
                  (element) => element.id == stepToDisplay.current
                );
                const isLastModule = currentModuleIndex === modules.length - 1;
                const isLastStepInModule =
                  !stepsSide[currentStepIndex + 1] ||
                  stepsSide[currentStepIndex + 1].id_step == null;
                const isFinalStepProgram = isLastModule && isLastStepInModule;

                // Check if prev or next button should be disabled
                if (currentModuleIndex === 0) {
                  //It's the first module and first step. There's no prev
                  setHidePrev(true);
                  setHideNext(false);
                } else if (isFinalStepProgram) {
                  //It's the last step of the last module
                  setHidePrev(false);
                  setHideNext(true);
                } else {
                  //It's somewhere in the middle
                  setHideNext(false);
                  setHidePrev(false);
                }

                if (isLastStepInModule) {
                  setValidateNext(false);
                }
                //Create array for sidebar
                const steps = [];

                for (let i = 0; i < response.data.data.steps.length; i++) {
                  i === 0
                    ? steps.push({
                        id: response.data.data.steps[i].id_step,
                        title: response.data.data.steps[i].title,
                        status: "complete",
                      })
                    : steps.push({
                        id: response.data.data.steps[i].id_step,
                        title: response.data.data.steps[i].title,
                        status: "pending",
                      });
                }
                setSidebar([...steps]);
                // 4. Update User
                axios
                  .put(url + `/user/update-user/${authUser.id}`, {
                    current_module: authUser.current_module,
                    current_step: authUser.current_step,
                  })
                  .then((response) => {
                    quickRender();
                  });
              });
          })
          .catch((error) => console.error(error));
      } else {
        // Progress doesn't need to be updated, there's a next step in the current module
        stepToDisplay.current = sidebar[currentIndex + 1].id;
        setBlockTitle(sidebar[currentIndex + 1].title);
        // Get modules to find current index
        axios
          .get(url + "/modules/get-modules/" + authUser.id)
          .then((response) => {
            const modules = response.data.data.modules;
            const currentModuleIndex = modules.findIndex(
              (element) => element.id_module === moduleToDisplay.current
            );
            axios
              .get(`${url}/modules/get-steps/${moduleToDisplay.current}`)
              .then((stepsResponse) => {
                const steps = stepsResponse.data.data.steps;

                const currentStepIndex = sidebar.findIndex(
                  (element) => element.id == stepToDisplay.current
                );

                const isLastModule = currentModuleIndex === modules.length - 1;
                const isLastStepInModule =
                  !steps[currentStepIndex + 1] ||
                  steps[currentStepIndex + 1].id_step == null;
                const isFinalStepProgram = isLastModule && isLastStepInModule;

                // Check if prev or next button should be disabled
                if (currentModuleIndex === 0 && currentStepIndex === 0) {
                  //It's the first module and first step. There's no prev
                  setHidePrev(true);
                  setHideNext(false);
                } else if (isFinalStepProgram) {
                  //It's the last step of the last module
                  setHidePrev(false);
                  setHideNext(true);
                } else {
                  //It's somewhere in the middle
                  setHideNext(false);
                  setHidePrev(false);
                }
                if (isLastStepInModule) {
                  setValidateNext(false);
                }
              });
          })
          .catch((error) => console.error(error));
        quickRender();
      }
    }
  };

  const onPrev = () => {
    setLoader(true);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    hasSurvey.current = false;
    surveyLength.current = 0;
    formAnswers.current = [];
    // Get index of current step
    const currentStepIndex = sidebar.findIndex(
      (element) => element.id == stepToDisplay.current
    );

    if (currentStepIndex > 0) {
      // Previous step is in the same module
      stepToDisplay.current = sidebar[currentStepIndex - 1].id;
      setBlockTitle(sidebar[currentStepIndex - 1].title);
      // Get modules to get index
      axios
        .get(url + "/modules/get-modules/" + authUser.id)
        .then((response) => {
          const modules = response.data.data.modules;
          const currentModuleIndex = modules.findIndex(
            (element) => element.id_module == moduleToDisplay.current
          );

          // Check if prev or next button should be disabled
          if (currentModuleIndex === 0 && currentStepIndex - 1 === 0) {
            //It's the first module and first step. There's no prev
            setHidePrev(true);
            setHideNext(false);
          } else if (
            currentModuleIndex === modules.length - 1 &&
            currentStepIndex - 1 === sidebar.length - 1
          ) {
            //It's the last step of the last module
            setHidePrev(false);
            setHideNext(true);
          } else {
            //It's somewhere in the middle
            setHideNext(false);
            setHidePrev(false);
          }
        });
      quickRender();
    } else {
      // Previous step is in the previous module
      // Get modules
      axios
        .get(url + "/modules/get-modules/" + authUser.id)
        .then((response) => {
          const modules = response.data.data.modules;
          // Get index of current module
          const currentModuleIndex = modules.findIndex(
            (element) => element.id_module == moduleToDisplay.current
          );
          moduleToDisplay.current = modules[currentModuleIndex - 1].id_module;
          // Set module title
          setModuleTitle(modules[currentModuleIndex - 1].module_title);
          // Get steps of previous module
          axios
            .get(url + `/modules/get-steps/${moduleToDisplay.current}`)
            .then((response) => {
              // Set step
              const currentStepIndex = response.data.data.steps.length - 1;
              stepToDisplay.current =
                response.data.data.steps[currentStepIndex].id_step;
              setBlockTitle(response.data.data.steps[currentStepIndex].title);
              // Sidebar content
              const steps = [];
              for (let i = 0; i < response.data.data.steps.length; i++) {
                steps.push({
                  id: response.data.data.steps[i].id_step,
                  title: response.data.data.steps[i].title,
                  status: "complete",
                });
              }
              setSidebar([...steps]);

              // Check if prev or next button should be disabled
              if (currentModuleIndex - 1 === 0 && currentStepIndex === 0) {
                //It's the first module and first step. There's no prev
                setHidePrev(true);
                setHideNext(false);
              } else if (
                currentModuleIndex - 1 === modules.length - 1 &&
                currentStepIndex === steps.length - 1
              ) {
                //It's the last step of the last module
                setHidePrev(false);
                setHideNext(true);
              } else {
                //It's somewhere in the middle
                setHideNext(false);
                setHidePrev(false);
              }
              quickRender();
            });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const goToStep = (step) => {
    setLoader(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    hasSurvey.current = false;
    surveyLength.current = 0;
    formAnswers.current = [];

    const currentStepIndex = sidebar.findIndex((s) => s.id === step.id);

    if (currentStepIndex !== -1) {
      setCurrentStepNumber(currentStepIndex + 1); // Configurar el número del paso (1-based index)
    } else {
      return;
    }

    const isLastStep = currentStepIndex === sidebar.length - 1;

    // Previous step is in the same module
    setCurrentStepNumber(step.number);
    stepToDisplay.current = step.id;
    setBlockTitle(step.title);

    // Get modules to get index
    axios.get(url + "/modules/get-modules/" + authUser.id).then((response) => {
      const modules = response.data.data.modules;
      const currentModuleIndex = modules.findIndex(
        (element) => element.id_module == moduleToDisplay.current
      );
      // Get index of current step
      const currentStepIndex = sidebar.findIndex(
        (element) => element.id == stepToDisplay.current
      );
      // Check if prev or next button should be disabled
      if (currentModuleIndex === 0 && currentStepIndex === 0) {
        //It's the first module and first step. There's no prev
        setHidePrev(true);
        setHideNext(false);
      } else if (currentModuleIndex === modules.length - 1 && isLastStep) {
        //It's the last step of the last module
        setHidePrev(false);
        setHideNext(true);
      } else {
        //It's somewhere in the middle
        setHideNext(false);
        setHidePrev(false);
      }

      if (isLastStep) {
        setValidateNext(false); // Si no hay validaciones pendientes
      } else {
        setValidateNext(
          hasSurvey.current && formAnswers.current.length < surveyLength.current
        );
      }
    });
    quickRender();
  };

  const nextModule = () => {
    // Obtener módulos
    axios
      .get(`${url}/modules/get-modules/${authUser.id}`)
      .then((response) => {
        const modules = response.data.data.modules;

        // Obtener índice del módulo actual
        const currentModuleIndex = modules.findIndex(
          (element) => element.id_module === moduleToDisplay.current
        );

        if (currentModuleIndex === -1) {
          console.error(
            "El módulo actual no se encontró en la lista de módulos."
          );
          return;
        }

        // Verificar si hay un siguiente módulo
        if (currentModuleIndex + 1 < modules.length) {
          const nextModule = modules[currentModuleIndex + 1];
          moduleToDisplay.current = nextModule.id_module;

          // Establecer título del módulo
          setModuleTitle(nextModule.module_title);

          // Obtener pasos del siguiente módulo
          axios
            .get(`${url}/modules/get-steps/${moduleToDisplay.current}`)
            .then((response) => {
              const steps = response.data.data.steps;

              if (!steps || steps.length === 0) {
                console.warn("No se encontraron pasos en el siguiente módulo.");
                setContent(
                  <p style={{ fontSize: "1.5em", textAlign: "center" }}>
                    Este módulo no tiene pasos disponibles.
                  </p>
                );
                setHideNext(true);
                return;
              }

              // Configurar el primer paso del siguiente módulo
              const currentStepIndex =
                authUser.current_module === moduleToDisplay.current
                  ? steps.findIndex(
                      (element) => element.id_step === authUser.current_step
                    )
                  : 0;

              stepToDisplay.current = steps[currentStepIndex].id_step;
              setBlockTitle(steps[currentStepIndex].title);

              // Actualizar contenido de la barra lateral
              const sidebarSteps = steps.map((step, index) => ({
                id: step.id_step,
                title: step.title,
                status: index <= currentStepIndex ? "complete" : "pending",
              }));
              setSidebar(sidebarSteps);

              // Actualizar el usuario en el backend
              axios
                .put(`${url}/user/update-user/${authUser.id}`, {
                  current_module: moduleToDisplay.current,
                  current_step: stepToDisplay.current,
                })
                .then(() => {
                  // Actualizar el usuario localmente
                  const updatedUser = { ...authUser };
                  updatedUser.current_module = moduleToDisplay.current;
                  updatedUser.current_step = stepToDisplay.current;
                  setAuthUser(updatedUser);

                  // Configurar botones de navegación
                  if (
                    currentModuleIndex + 1 === modules.length &&
                    currentStepIndex === steps.length - 1
                  ) {
                    // Es el último paso del último módulo
                    setHidePrev(false);
                    setHideNext(true);
                  } else if (
                    currentModuleIndex + 1 === 0 &&
                    currentStepIndex === 0
                  ) {
                    // Es el primer módulo y el primer paso
                    setHidePrev(true);
                    setHideNext(false);
                  } else {
                    // Está en el medio
                    setHideNext(false);
                    setHidePrev(false);
                  }

                  quickRender();
                })
                .catch((error) => {
                  console.error("Error al actualizar el usuario:", error);
                });
            })
            .catch((error) => {
              console.error(
                "Error al obtener los pasos del siguiente módulo:",
                error
              );
            });
        } else {
          // No hay más módulos disponibles
          setHideNext(true);
          setContent(
            <p style={{ fontSize: "1.5em", textAlign: "center" }}>
              ¡Felicidades! Has completado todos los módulos. 🎉
            </p>
          );
        }
      })
      .catch((error) => {
        console.error("Error al obtener los módulos:", error);
      });
  };

  const prevModule = () => {
    // Get modules
    axios
      .get(url + "/modules/get-modules/" + authUser.id)
      .then((response) => {
        const modules = response.data.data.modules;
        // Get index of current module
        const currentModuleIndex = modules.findIndex(
          (element) => element.id_module == moduleToDisplay.current
        );
        moduleToDisplay.current = modules[currentModuleIndex - 1].id_module;
        // Set module title
        setModuleTitle(modules[currentModuleIndex - 1].module_title);
        // Get steps of next module
        axios
          .get(url + `/modules/get-steps/${moduleToDisplay.current}`)
          .then((response) => {
            // Set step
            const currentStepIndex = response.data.data.steps.length - 1;
            stepToDisplay.current =
              response.data.data.steps[currentStepIndex].id_step;
            setBlockTitle(response.data.data.steps[currentStepIndex].title);
            // Sidebar content
            const steps = [];
            for (let i = 0; i < response.data.data.steps.length; i++) {
              steps.push({
                id: response.data.data.steps[i].id_step,
                title: response.data.data.steps[i].title,
                status: "complete",
              });
            }
            setSidebar([...steps]);
            // Check if prev or next button should be disabled
            if (currentModuleIndex - 1 === 0 && currentStepIndex === 0) {
              //It's the first module and first step. There's no prev
              setHidePrev(true);
              setHideNext(false);
            } else if (
              currentModuleIndex - 1 === modules.length - 1 &&
              currentStepIndex === steps.length - 1
            ) {
              //It's the last step of the last module
              setHidePrev(false);
              setHideNext(true);
            } else {
              //It's somewhere in the middle
              setHideNext(false);
              setHidePrev(false);
            }
            var isPast = false;
            if (stepToDisplay.current == steps[steps.length - 1].id) {
              if (
                steps.find((element) => element.id == stepToDisplay.current)
                  ?.status == "complete"
              ) {
                isPast = true;
              }
            }
            quickRender(isPast);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <div className={styles.vocaLayout}>
      {loader === true && (
        <div className={styles.loader}>
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="var(--primary)"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      {loader === false && (
        <>
          <div className={styles.vocaContainer}>
            <Breadcrumbs
              mainTitle={[
                { mainTitle: "Todos los módulos", link: "/progreso" },
              ]}
              current={`${
                currentModuleNumber ? currentModuleNumber + ". " : ""
              }${moduleTitle}`}
              section={{ number: currentStepNumber, title: blockTitle }}
            />
            <div className={styles.vocaLayout}>
              <div className={styles.col25}>
                <Sidebar
                  title={moduleTitle}
                  steps={sidebar}
                  goToStep={goToStep}
                  currentStep={stepToDisplay}
                  nextModule={nextModule}
                  prevModule={prevModule}
                />
              </div>
              <div className={styles.col75}>
                {blockTitle && (
                  <>
                    <h1>{blockTitle}</h1>
                    <div className={styles.divider}></div>
                  </>
                )}
                <div className={styles.content}>{content}</div>
                <div className={styles.btnGroup}>
                  {!hidePrev && (
                    <Button
                      text="Anterior"
                      type="outline"
                      icon="back"
                      corners="rounded"
                      onClick={onPrev}
                    />
                  )}
                  {!hideNext && (
                    <Button
                      text="Siguiente"
                      type={validateNext ? "disabled" : "filled"}
                      icon="next"
                      corners="rounded"
                      onClick={onNext}
                      disabled={validateNext}
                    />
                  )}
                  {hideNext && validateNext && (
                    <Button
                      text="Finalizar"
                      type="disabled"
                      corners="rounded"
                      disabled={validateNext}
                    />
                  )}
                  {hideNext && !validateNext && (
                    <Popup
                      user={authUser.id}
                      step={sidebar[sidebar.length - 1]?.id}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Mejorar estilos para adaptar el contenido flotante */}
          <PiBot />
        </>
      )}
    </div>
  );
}
