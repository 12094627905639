import axios from "axios";

import "./ProyectoDeAprendizaje.css";
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

import { ThreeDots } from "react-loader-spinner";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

import { EmptySidebar } from "../../components";
import ProyectoAprendizaje from "../ProyectoAprendizaje/ProyectoAprendizaje";
import Tendencias from "../Tendencias/Tendencias";
import Escenarios from "../Escenarios/Escenarios";
import EscenariosDynamic from "../Escenarios/EscenariosDynamic";

import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

const ProyectoDeAprendizaje = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [dataFirst, setDataFirst] = useState(null);
  const [dataSecond, setDataSecond] = useState(null);
  const [dataThrid, setDataThrid] = useState(null);
  const { authUser, setAuthUser, isLoggedIn, setIsLoggedIn } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const url = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "http://localhost:3501";

  const getData = async () => {
    setLoading(true);
    setError(false);

    const email = authUser?.email.replace(/"/g, "");
    try {
      const [firstResponse, secondResponse, thirdResponse] = await Promise.all([
        axios.get(`${url}/answers/get-learning-project-first/${email}`),
        axios.get(`${url}/answers/get-learning-project-second/${email}`),
        axios.get(`${url}/answers/get-learning-project-third/${email}`),
      ]);

      if (firstResponse.data.data?.message === "Datos encontrados") {
        setDataFirst(firstResponse.data.data);
      }

      if (secondResponse.data.data) {
        setDataSecond(secondResponse.data.data);
      }

      if (thirdResponse.data.data?.message === "Hay datos") {
        setDataThrid(thirdResponse.data.data);
      }
    } catch (err) {
      console.error("Error fetching data:", err);
      setError(true); // Marca un error si alguna solicitud falla
    } finally {
      setLoading(false); // Marca loading como falso después de todas las solicitudes
    }
  };

  const sendLearningProjectEmail = async () => {
    try {
      const response = await fetch(
        "http://localhost:3501/auth/send-learning-project-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Error sending email:", errorText);
        alert("Error: " + errorText);
        return;
      }

      const result = await response.json();
      alert("Success: " + result.message);
    } catch (error) {
      console.error("Network error:", error);
      alert("Network error: Unable to send email. Please try again later.");
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      /*if (authUser.current_module !== 'ffc340f4-eea3-4ab5-8430-d65fb062a6e8') {
                window.location.href = '/miespacio';
                return
            }*/

      getData();
    }
  }, [authUser, isLoggedIn]);

  useEffect(() => {
    const storedUser = Cookies.get("authUser");
    if (!isLoggedIn && storedUser) {
      const user = JSON.parse(storedUser);
      if (user && user.token) {
        setAuthUser(user);
        setIsLoggedIn(true);
      } else {
        setAuthUser(null);
        setIsLoggedIn(false);
        navigate("/login");
      }
    } else if (!isLoggedIn) {
      navigate("/login");
    }

    if (isLoggedIn) {
      /*if (authUser?.current_module !== 'ffc340f4-eea3-4ab5-8430-d65fb062a6e8') {
                window.location.href = '/miespacio';
                return;
            }
            */
      getData();
    }
  }, []);

  const options = [
    {
      id: 0,
      name: "Mi propósito de vida",
      icon: <ArrowForwardIosRoundedIcon />,
      onClick: () => setCurrentTab(0),
    },
    {
      id: 1,
      name: "Tendencias aspiraciones profesionales",
      icon: <ArrowBackIosRoundedIcon />,
      onClick: () => setCurrentTab(1),
    },
    {
      id: 2,
      name: "Escenarios",
      icon: <ArrowBackIosRoundedIcon />,
      onClick: () => setCurrentTab(2),
    },
  ];

  const content = (currentTab) => {
    if (loading) {
      return (
        <ThreeDots
          visible={true}
          height="80"
          width="80"
          color="var(--primary)"
          ariaLabel="three-dots-loading"
        />
      );
    }

    if (error || (dataFirst === null && currentTab === 0)) {
      return (
        <div className="error-container">
          <p className="error-title">Parece que no se cargó tu información correctamente.</p>
          <p className="error-details">
            Por favor revisa tus respuestas para ver el contenido aquí.
          </p>
        </div>
      );
    }

    switch (currentTab) {
      case 0:
        return dataFirst ? (
          <ProyectoAprendizaje aprendizajeData={dataFirst} />
        ) : (
          <div className="error-container">
            <p className="error-title">Parece que no se cargó tu información correctamente.</p>
            <p className="error-details">
              Por favor revisa tus respuestas para ver el contenido aquí.
            </p>
          </div>
        );
      case 1:
        return dataSecond ? (
          <Tendencias tendenciasData={dataSecond} />
        ) : (
          <div className="error-container">
            <p className="error-title">Parece que no se cargó tu información correctamente.</p>
            <p className="error-details">
              Por favor revisa tus respuestas para ver el contenido aquí.
            </p>
          </div>
        );
      case 2:
        return dataThrid ? (
          authUser.id_program ? (
            <EscenariosDynamic escenariosData={dataThrid} />
          ) : (
            <Escenarios escenariosData={dataThrid} />
          )
        ) : (
          <div className="error-container">
            <p className="error-title">Parece que no se cargó tu información correctamente.</p>
            <p className="error-details">
              Por favor revisa tus respuestas para ver el contenido aquí.
            </p>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className="aprendizaje-layout">
        <div className="col-25">
          <EmptySidebar
            currentOption={currentTab}
            options={options}
            title="Proyecto de Aprendizaje"
          />
        </div>
        <div className="col-75" id="section-to-print">
          {content(currentTab)}
        </div>
      </div>
    </>
  );
};

export default ProyectoDeAprendizaje;
